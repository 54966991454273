import { EventEmitter, Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventServiceForMatchingPlanService {
  invokeAppointmentComponentFunction = new EventEmitter(); 
  subsVar: Subscription;    
  constructor() { }


      
    
  onSearchAndMatchPlantComponentButtonClick() {    
    this.invokeAppointmentComponentFunction.emit();    
  }    
}
