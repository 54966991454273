import { FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function ContainCapitalizationNumSym(controlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];

        // return null if controls haven't initialised yet
        if (!control) {
            return null;
        }

        // return null if another validator has already found an error on the matchingControl
        if (control.errors && !control.errors.containCapital && !control.errors.containNumOrSym) {
            return null;
        }


        // set error on matchingControl if validation fails
        var containCapitalRegex = new RegExp('(?=[^A-Z]*[A-Z])');
        var containSymbolRegex = new RegExp(/[\-#@!$%^()_+~:'?.0123456789]/);


        // 1 Uppercase requirement     
        if (!containCapitalRegex.test(control.value)) {
            control.setErrors({ containCapital: true });
        // 1 Symbol or Number requirement
        } else if (!containSymbolRegex.test(control.value)) {
            control.setErrors({ containNumOrSym: true });
        }
        else {
            control.setErrors(null);
        }
    }
}