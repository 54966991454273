import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EligibilityRequestFormBody } from 'src/app/Custom Datatypes/EligibilityRequestFormBody';
import { Payer } from 'src/app/Custom Datatypes/Payer';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';


function isValidDate(c: AbstractControl) {
  let dateString = c.value;
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return {"invalidDate": true};

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10); // i.e Converts 3 or 03 to Integer 3. 
  var year = parseInt(parts[2], 10);

  // Check future date
  var enteredDateOfBirth = new Date(year, month - 1, day);
  var currentDate = new Date();
  if(enteredDateOfBirth > currentDate)
  {
    return {"invalidDate": true};
  }

  // Check the ranges of month and year
  if (year < 1900 || year > 2100 || month == 0 || month > 12)
      return {"invalidDate": true};

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years. If input year is a leap year, update monthLenth array for February. 
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

  // Last thing to check is the range of the day
  return (day > 0 && day <= monthLength[month - 1]) ? null: {"invalidDate": true};
}

function isValidName(c: AbstractControl){
  if((c.value as string).indexOf(' ') >= 0){
    return {cannotContainSpace: true}
}
  return null;
}

@Component({
  selector: 'app-send-eligibility-modal',
  templateUrl: './send-eligibility-modal.component.html',
  styleUrls: ['./send-eligibility-modal.component.scss']
})
export class SendEligibilityModalComponent implements OnInit {
  requestForm: FormGroup;
  errorMessage: string = "";
  apptdate: string = "";
  mappedId: number;
  dentifiType: string = localStorage.getItem("dentifiType");
  dentifiService: string = localStorage.getItem("clientServices");
  submitRequestFormBody: EligibilityRequestFormBody = new EligibilityRequestFormBody();
  payerList: Payer[];
  payerMessage: string = "";
  isSubFieldsdisabled:boolean=true;
  isDepFieldsdisabled:boolean=true;
  payer:any;
  relToSub:string;
  firstLoad:boolean=true;
  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService) { }

  ngOnInit() {
    this.getPayerList();
    this.requestForm = this.fb.group({
      payer: ['-1', Validators.required],
      subChart: [''],
      subFName: [{value:'',disabled:this.isSubFieldsdisabled}, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
      subLName: [{value:'',disabled:this.isSubFieldsdisabled}, [Validators.required, Validators.pattern('^[a-zA-Z- ]*$')]],
      subDOB: [{value:'',disabled:this.isSubFieldsdisabled}, [Validators.required, isValidDate]],
      subSSNOrMemId: [{value:'',disabled:this.isSubFieldsdisabled}, Validators.required],
      patChart: [''],
      patFName: [{value:'',disabled:this.isDepFieldsdisabled}, [Validators.required, Validators.pattern('^[a-zA-Z ]*$')]],
      patLName: [{value:'',disabled:this.isDepFieldsdisabled}, [Validators.required, Validators.pattern('^[a-zA-Z- ]*$')]],
      patDOB: [{value:'',disabled:this.isDepFieldsdisabled},[Validators.required, isValidDate]],
      patRelToSub: ['-1'],
      subZipCode: ['',Validators.required],
      groupNo: [''],
    });

  }

  getPayerList() {
    this.dentifiAPIService.getPayerList().subscribe({
      next: payerList => {
        this.payerList = payerList;
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });  
  }

  submit() {

      this.submitRequestFormBody.TTUniqueId = parseInt(localStorage.getItem("ttuniqueId"));
      this.submitRequestFormBody.TrojanAcctNumber = localStorage.getItem("trojanAcctNumber");
      this.submitRequestFormBody.SubChart = this.requestForm.get("subChart").value;
      this.submitRequestFormBody.SubFName = this.requestForm.get("subFName").value;
      this.submitRequestFormBody.SubLName = this.requestForm.get("subLName").value;
      this.submitRequestFormBody.SubDOB = this.requestForm.get("subDOB").value;
      this.submitRequestFormBody.ApptDate = this.apptdate;

      this.submitRequestFormBody.SubSSNOrMemId = this.requestForm.get("subSSNOrMemId").value;
      this.submitRequestFormBody.SubZip = this.requestForm.get("subZipCode").value;
      this.submitRequestFormBody.MappedPayerId = parseInt(this.requestForm.get("payer").value);
      this.submitRequestFormBody.SubChart = this.requestForm.get("subChart").value;
      
      if(this.relToSub =='dep'){
      this.submitRequestFormBody.PatChart = this.requestForm.get("patChart").value;
      this.submitRequestFormBody.PatFName = this.requestForm.get("patFName").value;
      this.submitRequestFormBody.PatLName = this.requestForm.get("patLName").value;
      this.submitRequestFormBody.PatDOB = this.requestForm.get("patDOB").value;
      }else{
        this.submitRequestFormBody.PatChart = this.submitRequestFormBody.SubChart;
        this.submitRequestFormBody.PatFName = this.submitRequestFormBody.SubFName;
        this.submitRequestFormBody.PatLName = this.submitRequestFormBody.SubLName;
        this.submitRequestFormBody.PatDOB = this.submitRequestFormBody.SubDOB;
      }
      this.submitRequestFormBody.GroupNo = this.requestForm.get("groupNo").value;

      this.submitRequestFormBody.RelToSub = this.relToSub; //this.requestForm.get("patRelToSub").value;


     this.activeModal.close(this.submitRequestFormBody);
    
  }

  cancelRequest() {
    this.activeModal.close();
  }





  changePayer(e) {
    this.firstLoad=false;
    let value = e.target.value.split(";");
    if (value.length == 2) {
      this.payerMessage = value[1].trim();
    }
    this.requestForm.get("payer").setValue(e.target.value, {
      onlySelf: true
    });

    let p= this.payerList.find(x=> x.PayerNo==value[0]);
    if(p) this.payer=p;
  }

  changeRel(e) {
    this.requestForm.get("patRelToSub").setValue(e.target.value, {
      onlySelf: true
    });    

    if (this.requestForm.get("patRelToSub").value == 'self') {
      this.requestForm.get("patFName").setValue(this.requestForm.get("subFName").value,{
        onlySelf: true
      });
      this.requestForm.get("patLName").setValue(this.requestForm.get("subLName").value,{
        onlySelf: true
      });
      this.requestForm.get("patDOB").setValue(this.requestForm.get("subDOB").value,{
        onlySelf: true
      });
      this.requestForm.get("patChart").setValue(this.requestForm.get("subChart").value,{
        onlySelf: true
      });
    }
  }

  populateSlashes(e,control){
	
    //To accomdate for backspacing, we detect which key was pressed - if backspace, do nothing:
    if(e.which !== 8) {	
      let cValue = control.value;
      var numChars = cValue.length;
      if(numChars === 2 || numChars === 5){
        var thisVal = cValue;
        thisVal += '/';
        control.setValue(thisVal);
      }
    }
  }

  onSubChange(event){
    if(event && event.target.checked==true){
    this.isSubFieldsdisabled=false;
    this.isDepFieldsdisabled=true;
    this.relToSub="self";
    }
    else
    this.isSubFieldsdisabled=true;
  }

  onDepChange(event)
{
  if(event && event.target.checked==true){
  this.isDepFieldsdisabled=false;
  this.isSubFieldsdisabled=true;
  this.relToSub="dep";
  }
  else
  this.isDepFieldsdisabled=true;
}
}
