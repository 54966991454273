import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientNetwork } from 'src/app/Custom Datatypes/ClientNetwork';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';

@Component({
  selector: 'app-client-network',
  templateUrl: './client-network.component.html',
  styleUrls: ['./client-network.component.css']
})
export class ClientNetworkComponent implements OnInit {
  selectedNetworks: ClientNetwork[];
  allNetworks: ClientNetwork[];
  custNo: string = localStorage.getItem("trojanAcctNumber");
  errorMessage: string = "";
  chosenNetworks: ClientNetwork[];
  showChosenOption: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("showChosenOption") as HTMLCollectionOf<HTMLInputElement>; 


  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService){ }

  ngOnInit() {
    this.selectedNetworks = null;
    this.dentifiAPIService.getClientNetworks(this.custNo).subscribe({
      next: response => {
        this.allNetworks = response;
        this.chosenNetworks = response.filter(x => x.RecordId > 0);
        if (this.showChosenOption.item(0).checked) {
          this.selectedNetworks = this.chosenNetworks;
        } else {
          this.selectedNetworks = this.allNetworks;
        }
        
      },
      error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
      }
    });

    
  }
  toggleNetworks(e) {
    if (e.target.value == "S") {
      this.selectedNetworks = this.chosenNetworks;
    } else {
      this.selectedNetworks = this.allNetworks;
    }
  }

  submit() {
    this.dentifiAPIService.saveClientNetworks(this.selectedNetworks).subscribe({
      next: response => {
        this.ngOnInit();
      },
      error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
          this.ngOnInit();
      }
    }); 
  }

  cancelRequest() {
    this.activeModal.close();
  }



}
