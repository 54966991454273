
export class PatientSubscriberInfoRequest {
    TTUniqueId: number;
    PatChart: string;
    PatientId: number;
    TrojanAcct: string;


    constructor(ttUniqueId: number, patChart: string, patientId: number, trojanAcct: string ) {
        this.TTUniqueId = ttUniqueId;
        this.PatChart = patChart;
        this.PatientId = patientId;
        this.TrojanAcct = trojanAcct;
    }
}