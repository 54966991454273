import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';



//Third Party Imports
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';


//App Imports
import { AppComponent } from './app.component';
//import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { LoginComponent } from './security/login.component';
import { AppointmentComponent } from './appointment/appointment.component';
import { AuthGuardService } from './security/auth.guard';
//import { ForgotPasswordComponent } from './security/forgot-password.component';
//import { ResetPasswordComponent } from './security/reset-password.component';
//import { MustMatchDirective } from './CustomeDirectives/MustMatchValidator/MustMatchDirective';
//import { PasswordValidator } from './CustomeDirectives/PasswordRequirementValidator/PasswordValidator';
import { DisableControlDirective } from './CustomeDirectives/disableControl';
import { NavMenuApptComponent } from './nav-menu-appt/nav-menu-appt.component';
import { BenefitEligTabsetComponent } from './shared/benefit-elig-tabset.component';
import { RequestFormModalComponent } from './shared/request form/request-form-modal.component';
//import { SpinnerComponent } from './shared/Spinner/spinner.component';
import {NgxPrintModule} from 'ngx-print';
import { SearchPlanComponent } from './shared/search plan/search-plan.component';
import { SearchAndMatchPlanComponent } from './shared/SearchPlansForMatching/search-and-match-plan.component';
import { ManualAppointmentsComponent } from './ManualAppointments/manual-appointments.component';
import { ManualBenEligInfoComponent } from './ManualAppointments/ManualBenEligInfoComponent/manual-ben-elig-info.component';
import { SendEligibilityModalComponent } from './ManualAppointments/SendEligibilityModal/send-eligibility-modal.component';
import { ClientNetworkComponent } from './shared/ClientNetwork/client-network.component';
import { UserManagementComponent } from './shared/UserManagement/user-management.component';
import { RequestlogComponent } from './shared/RequestLog/requestlog.component';
import { MetricsComponent } from './shared/Metrics/metrics.component';
import { NotificationComponent } from './shared/ClientNotification/notification.component';
//import {ConfirmMailComponent} from './security/confirm-mail.component';
import { MustMatchModule } from './CustomeDirectives/MustMatchValidator/MustMatch.module';
import { PasswordValidatorModule } from './CustomeDirectives/PasswordRequirementValidator/PasswordValidator.module';
import { SpinnerModule } from './shared/Spinner/spinner.module';

import { ExportApptComponent } from './shared/export-appt/export-appt.component';
import { TwoFAPopUpComponent } from './security/2fa/2fa-popup/two-fa-pop-up.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



@NgModule({
  declarations: [
    AppComponent,
    //NavMenuComponent,
    //LoginComponent,
    AppointmentComponent,
    //ForgotPasswordComponent,
    //ResetPasswordComponent,
    //MustMatchDirective,
    //PasswordValidator,
    DisableControlDirective,
    NavMenuApptComponent,
    BenefitEligTabsetComponent,
    RequestFormModalComponent,
    //SpinnerComponent,
    SearchPlanComponent,
    SearchAndMatchPlanComponent,
    ManualAppointmentsComponent,
    ManualBenEligInfoComponent,
    SendEligibilityModalComponent,
    ClientNetworkComponent,
    UserManagementComponent,
    RequestlogComponent,
    MetricsComponent,
    NotificationComponent,
    //ConfirmMailComponent
    ExportApptComponent,
    TwoFAPopUpComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    RouterModule.forRoot([
      { path: 'login', loadChildren:()=>import('./security/login.module').then((m)=>{return m.LoginModule}) },
      { path: 'forgotpassword', loadChildren:()=>import('./security/forgot-password.module').then((m)=>{return m.ForgotPasswordModule})},
      { path: 'resetpassword', loadChildren:()=>import('./security/reset-password.module').then((m)=>{return m.ResetPasswordModule}) },
      { path: 'confirmmail',  loadChildren:()=>import('./security/confirm-mail.module').then((m)=>{return m.ConfirmMailModule}) },
      { path: 'appointments', component: AppointmentComponent, canActivate: [AuthGuardService]},
      { path: 'ManualAppointments', component: ManualAppointmentsComponent, canActivate: [AuthGuardService]},
      { path: 'Requestlog', component: RequestlogComponent,canActivate: [AuthGuardService]},
      { path: '2fa', loadChildren:()=>import('./security/2fa/two-fa.module').then((m)=>{return m.TwoFAModule})},
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: '**', loadChildren:()=>import('./security/login.module').then((m)=>{return m.LoginModule}) /*load pagenotfound componetn here */}
    ]),
    NgbModule,
    NgxPrintModule,
    MustMatchModule,
    PasswordValidatorModule,
    SpinnerModule,
    MatSlideToggleModule
  ],
  providers: [],
  entryComponents: [RequestFormModalComponent,SearchPlanComponent,SendEligibilityModalComponent,ClientNetworkComponent, UserManagementComponent, RequestlogComponent, MetricsComponent,NotificationComponent,ExportApptComponent,TwoFAPopUpComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
