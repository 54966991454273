
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EligibilityElement } from 'src/app/Custom Datatypes/EligibilityElement';
import { ManualAppointments } from 'src/app/Custom Datatypes/ManualAppointments';
import { PatientRequestFormBody } from 'src/app/Custom Datatypes/PatientRequestFormBody';
import { PatientRequestInfo } from 'src/app/Custom Datatypes/PatientRequestInfo';
import { RemainingBalDownloadTime } from 'src/app/Custom Datatypes/RemainingBalDownloadTime';
import { SubmitRequestFormBody } from 'src/app/Custom Datatypes/SubmitRequestFormBody';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';

function subSSNOrsubMembIdExists(c: AbstractControl): {[key: string]: boolean} | null {
  let ssnControl = c.get("subSSN");
  let subMemId = c.get("subMemId");


  if (ssnControl.value == "" && subMemId.value == "") {
    return { "SsnMembIdEmpty": true}
  }
  return null;
}

function atleastOneCheckboxChecked(c: AbstractControl): {[key: string]: boolean} | null {
  let reqElig = c.get("reqElig");
  let reqFax = c.get("reqFax");
  let reqTP = c.get("reqTP");
  let reqPH = c.get("reqPH");

  if (reqElig.value == false && reqFax.value == false && reqTP.value == false && reqPH.value == false ) {
    return { "noCheckboxesChecked": true}
  }
  return null;
}

function isFutureDate(c: AbstractControl){
  let dateString = c.value

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10); // i.e Converts 3 or 03 to Integer 3. 
  var year = parseInt(parts[2], 10);

 // Check future date
  var enteredDateOfBirth = new Date(year, month - 1, day);
  var currentDate = new Date();
  if(enteredDateOfBirth > currentDate)
  {
    return {"invalidDate": true};
  }
  return null;
}

function isValidDate(c: AbstractControl) {
  let dateString = c.value;
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return {"invalidDate": true};

  // Parse the date parts to integers
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10); // i.e Converts 3 or 03 to Integer 3. 
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1900 || year > 2100 || month == 0 || month > 12)
      return {"invalidDate": true};

  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years. If input year is a leap year, update monthLenth array for February. 
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

  // Last thing to check is the range of the day
  return (day > 0 && day <= monthLength[month - 1]) ? null: {"invalidDate": true};
}

function isInValidSSN(c: AbstractControl) {
  let ssn = c.value;

   if(!/^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/.test(ssn) && ssn.length > 0) return {"invalidSSN": true};
  
  return null;
}

function isInValidID(c: AbstractControl) {
  let Id = c.value;

   if(!/^(?=.*[a-zA-Z0-9])/.test(Id) && Id.length > 0) return {"invalidID": true};
  
  return null;
}
function isInValidPhone(c: AbstractControl) {
  let phone = c.value;
   if(!/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(phone.trim()) && phone.length > 0) return {"invalidPhone": true};
  
  return null;
}
function isInvalidTime(c: AbstractControl){
  let time = c.value;
  if (!/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/.test(time.trim()) && time.length > 0) {
    return { "invalidTime":true };
  }
  return null;
}
function isValidName(c: AbstractControl){
  if((c.value as string).indexOf(' ') >= 0){
    return {cannotContainSpace: true}
}
  return null;
}




@Component({
  selector: 'app-request-form-modal',
  templateUrl: './request-form-modal.component.html',
  styleUrls: ['./request-form-modal.component.scss']
})
export class RequestFormModalComponent implements OnInit {
  requestForm: FormGroup;
  _allState: any[] = [
   { StateName: 'Alabama', StateId: 'AL' }, { StateName: 'Alaska', StateId: 'AK' }, { StateName: 'American Samoa', StateId: 'AS' }, { StateName: 'Arizona', StateId: 'AZ' }, { StateName: 'Arkansas', StateId: 'AR' }, { StateName: 'California', StateId: 'CA' }, { StateName: 'Colorado', StateId: 'CO' }, { StateName: 'Connecticut', StateId: 'CT' }, { StateName: 'Delaware', StateId: 'DE' }, { StateName: 'District Of Columbia', StateId: 'DC' }, { StateName: 'Florida', StateId: 'FL' }, { StateName: 'Georgia', StateId: 'GA' }, { StateName: 'Guam', StateId: 'GU' }, { StateName: 'Hawaii', StateId: 'HI' }, { StateName: 'Idaho', StateId: 'ID' }, { StateName: 'Illinois', StateId: 'IL' }, { StateName: 'Indiana', StateId: 'IN' }, { StateName: 'Iowa', StateId: 'IA' }, { StateName: 'Kansas', StateId: 'KS' }, { StateName: 'Kentucky', StateId: 'KY' }, { StateName: 'Louisiana', StateId: 'LA' }, { StateName: 'Maine', StateId: 'ME' }, { StateName: 'Marshall Islands', StateId: 'MH' }, { StateName: 'Maryland', StateId: 'MD' }, { StateName: 'Massachusetts', StateId: 'MA' }, { StateName: 'Michigan', StateId: 'MI' }, { StateName: 'Minnesota', StateId: 'MN' }, { StateName: 'Mississippi', StateId: 'MS' }, { StateName: 'Missouri', StateId: 'MO' }, { StateName: 'Montana', StateId: 'MT' }, { StateName: 'Nebraska', StateId: 'NE' },
    { StateName: 'Nevada', StateId: 'NV' }, { StateName: 'New Hampshire', StateId: 'NH' }, { StateName: 'New Jersey', StateId: 'NJ' }, { StateName: 'New Mexico', StateId: 'NM' }, { StateName: 'New York', StateId: 'NY' }, { StateName: 'North Carolina', StateId: 'NC' }, { StateName: 'North Dakota', StateId: 'ND' },
    { StateName: 'Ohio', StateId: 'OH' }, { StateName: 'Oklahoma', StateId: 'OK' }, { StateName: 'Oregon', StateId: 'OR' }, { StateName: 'Palau', StateId: 'PW' }, { StateName: 'Pennsylvania', StateId: 'PA' }, { StateName: 'Puerto Rico', StateId: 'PR' }, { StateName: 'Rhode Island', StateId: 'RI' },
    { StateName: 'South Carolina', StateId: 'SC' }, { StateName: 'South Dakota', StateId: 'SD' }, { StateName: 'Tennessee', StateId: 'TN' }, { StateName: 'Texas', StateId: 'TX' }, { StateName: 'Utah', StateId: 'UT' }, { StateName: 'Vermont', StateId: 'VT' }, { StateName: 'Virgin Islands', StateId: 'VI' }, { StateName: 'Virginia', StateId: 'VA' }, { StateName: 'Washington', StateId: 'WA' }, { StateName: 'West Virginia', StateId: 'WV' }, { StateName: 'Wisconsin', StateId: 'WI' }, { StateName: 'Wyoming', StateId: 'WY' }
  ];
  errorMessage: string = "";
  trojanHolidays: Date[];
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  eligibilityElement: EligibilityElement;
  manualAppt: ManualAppointments;
  forManualPatient: boolean;
  isPri: boolean;
  apptdate: string = "";
  patientRequestFormDetails: PatientRequestInfo;
  mappedId: number;
  dentifiType: string = localStorage.getItem("dentifiType");
  dentifiService: string = localStorage.getItem("clientServices");
  canDoElig: boolean;
  canDoFax: boolean;
  canDoTP: boolean = false;
  canDoPH: boolean = false;
  trojanId: string = "";
  remainingBalnce: number;
  submitRequestFormBody: SubmitRequestFormBody = new SubmitRequestFormBody();
  balanceAndDownloadTime: RemainingBalDownloadTime = new RemainingBalDownloadTime();
  isEligRequestPending : boolean=false;
  isTPRequestPending : boolean=false;

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService) { }

  ngOnInit() {
    //Get Remaining Balance
    this.dentifiAPIService.getRemainingBalanceAndDownloadDate(this.trojanAcct,parseInt(this.ttuniqueId)).subscribe({
      next: resp => {
        Object.assign(this.balanceAndDownloadTime, resp);
        this.remainingBalnce = this.balanceAndDownloadTime.remainingBalance;
      },
      error: err => {
        console.log(err);
      }
    });
    //Get Trojan Holidays
    this.getTrojanHolidays();
    if(!this.forManualPatient){
      //check existing request status
      this.getExistingRequestStatus();
    }

    if (this.forManualPatient) {
      this.getPatientRequestFormDetails(null);
    } else {
    //Determine if this is for pri or sec insurance request
      if (this.isPri) {
        this.mappedId = this.eligibilityElement.PriMappedId;
        if (this.eligibilityElement.BenefitInfo) {
          this.trojanId = this.eligibilityElement.BenefitInfo.PriTrojanId;
        }

      } else {
        this.mappedId = this.eligibilityElement.SecMappedId;
        if (this.eligibilityElement.BenefitInfo) {
          this.trojanId = this.eligibilityElement.BenefitInfo.SecTrojanId;
        }
      }
      let patientRequestFormBody = new PatientRequestFormBody();
      patientRequestFormBody.MappedPayerId = this.mappedId;
      patientRequestFormBody.IsPri = this.isPri;
      patientRequestFormBody.TTuniqueId = parseInt(localStorage.getItem("ttuniqueId"));
      patientRequestFormBody.PatChart = this.eligibilityElement.Chart;
      patientRequestFormBody.TrojanId = this.trojanId;
      patientRequestFormBody.PatientId = this.eligibilityElement.PatientId;
      this.getPatientRequestFormDetails(patientRequestFormBody);
    }
    


    //Get Patient Request Form autopopulate field data
 




  }

  submit() {
    let message = "";
    if (this.manualAppt){}
    else {
      //if all checkboxes are disbaled or uncheck
      if(this.requestForm.get('reqCheckBoxes.reqElig').value == false
      && this.requestForm.get('reqCheckBoxes.reqFax').value == false
      && this.requestForm.get('reqCheckBoxes.reqTP').value == false
      && this.requestForm.get('reqCheckBoxes.reqPH').value == false
      ){
        alert("Please select at least 1 of the request types!");
        return;
      }
      if ((this.patientRequestFormDetails.PrevPHStatus.trim().toUpperCase() == "Q" || this.patientRequestFormDetails.PrevPHStatus.trim().toUpperCase() == "I") && this.requestForm.get("reqCheckBoxes.reqPH").value) {
        alert("PH request already in queue. Request cannot be sent again.");
        return;
      }
    }

    if (this.requestForm.get('reqCheckBoxes.reqTP').value || this.requestForm.get('reqCheckBoxes.reqPH').value) {
      if (this.remainingBalnce <= 0) {
        alert("You Have Exceeded Your Number of Plan/Patient History Requests Based On Your Contract For This Month. Please Call Trojan Support For More Info.");
        return;
      } else if(this.remainingBalnce == 999) {
        message = "Attention: You are requesting to have additional research completed by Trojan. This request includes a research fee which will be included on your next statement.\nFor more information contact Accounting at 800 451-9723 ext:4.\n\nBy clicking OK you agree to these terms.";
      } else {
        message = "There is no additional fee to make this request. OK to proceed?";
      }
    } else {
      message = "There is no additional fee to make this request. OK to proceed?";
    }
    let confirmMessage = confirm(message);
    if (confirmMessage) {
      if(this.forManualPatient) {
        this.submitRequestFormBody.TTUniqueId = parseInt(localStorage.getItem("ttuniqueId"));
        this.submitRequestFormBody.SubFName = this.requestForm.get("subFName").value;
        this.submitRequestFormBody.SubLName = this.requestForm.get("subLName").value;
        this.submitRequestFormBody.SubDOB = this.requestForm.get("subDOB").value;
        this.submitRequestFormBody.IsPri = this.isPri;
        this.submitRequestFormBody.ApptId = -1000;
        this.submitRequestFormBody.ApptDate = this.apptdate;
        this.submitRequestFormBody.TrojanAcctNumber = localStorage.getItem("trojanAcctNumber");
        this.submitRequestFormBody.SubSSN = this.requestForm.get("subSSNMemIdGroup.subSSN").value;
        this.submitRequestFormBody.SubID = this.requestForm.get("subSSNMemIdGroup.subMemId").value;
        this.submitRequestFormBody.SubZip =  this.requestForm.get("subZip").value;//this.manualAppt.SubZip.toString();
        this.submitRequestFormBody.MappedPayerId = this.mappedId;
        this.submitRequestFormBody.PatFName = this.requestForm.get("patFName").value;
        this.submitRequestFormBody.PatLName = this.requestForm.get("patLName").value;
        this.submitRequestFormBody.PatDOB = this.requestForm.get("patDOB").value;
        this.submitRequestFormBody.PatID = this.manualAppt.ID.toString();
        this.submitRequestFormBody.EmployerName = this.requestForm.get("employerName").value;
        this.submitRequestFormBody.EmployerCity = this.requestForm.get("employerCity").value;
        this.submitRequestFormBody.EmployerState = this.requestForm.get("employerState").value;
        this.submitRequestFormBody.EmployerPhone = this.requestForm.get("employerPhone").value;
        this.submitRequestFormBody.EmployerZip = this.requestForm.get("employerZip").value;
        this.submitRequestFormBody.EmployerFax = this.requestForm.get("employerFax").value;
  
        this.submitRequestFormBody.TrojanId = this.trojanId;
        this.submitRequestFormBody.CarrierName = this.requestForm.get("carrName").value;
        this.submitRequestFormBody.CarrierGroupNo = this.requestForm.get("carrGroupNo").value;
        this.submitRequestFormBody.CarrierCity = this.requestForm.get("carrCity").value;
        this.submitRequestFormBody.CarrierState = this.requestForm.get("carrState").value;
        this.submitRequestFormBody.CarrierPhone = this.requestForm.get("carrPhone").value;
        this.submitRequestFormBody.CarrCode = "";
        this.submitRequestFormBody.PrefProvider = this.requestForm.get("carrPref").value;
  
        this.submitRequestFormBody.DateNeeded = this.requestForm.get("dateNeeded").value;
        this.submitRequestFormBody.TimeNeeded = this.requestForm.get("timeNeeded").value;
        this.submitRequestFormBody.ReturnMethod = this.requestForm.get("returnBy").value;
  
        this.submitRequestFormBody.Submitted = this.requestForm.get("submitted").value;
        this.submitRequestFormBody.Remarks = this.requestForm.get("remarks").value;
  
        this.submitRequestFormBody.IsEligChecked = this.requestForm.get("reqCheckBoxes.reqElig").value;
        this.submitRequestFormBody.IsFaxChecked = this.requestForm.get("reqCheckBoxes.reqFax").value;
        this.submitRequestFormBody.IsTPChecked = this.requestForm.get("reqCheckBoxes.reqTP").value;
        this.submitRequestFormBody.IsPHChecked = this.requestForm.get("reqCheckBoxes.reqPH").value;

        this.submitRequestFormBody.ForManualPatient = true;

      } else {
        this.submitRequestFormBody.TTUniqueId = parseInt(localStorage.getItem("ttuniqueId"));
        this.submitRequestFormBody.SubFName = this.requestForm.get("subFName").value;
        this.submitRequestFormBody.SubLName = this.requestForm.get("subLName").value;
        this.submitRequestFormBody.SubDOB = this.requestForm.get("subDOB").value;
        this.submitRequestFormBody.IsPri = this.isPri;
        this.submitRequestFormBody.ApptId = this.eligibilityElement.ApptId;
        this.submitRequestFormBody.ApptDate = this.apptdate;
        this.submitRequestFormBody.TrojanAcctNumber = localStorage.getItem("trojanAcctNumber");
        this.submitRequestFormBody.SubSSN = this.requestForm.get("subSSNMemIdGroup.subSSN").value;
        this.submitRequestFormBody.SubID = this.requestForm.get("subSSNMemIdGroup.subMemId").value;
        this.submitRequestFormBody.SubZip = this.requestForm.get("subZip").value; //this.patientRequestFormDetails.ZipCode;
        this.submitRequestFormBody.MappedPayerId = this.mappedId;
        this.submitRequestFormBody.PatFName = this.requestForm.get("patFName").value;
        this.submitRequestFormBody.PatLName = this.requestForm.get("patLName").value;
        this.submitRequestFormBody.PatDOB = this.requestForm.get("patDOB").value;
        this.submitRequestFormBody.PatID = this.eligibilityElement.PatientId.toString();
        this.submitRequestFormBody.EmployerName = this.requestForm.get("employerName").value;
        this.submitRequestFormBody.EmployerCity = this.requestForm.get("employerCity").value;
        this.submitRequestFormBody.EmployerState = this.requestForm.get("employerState").value;
        this.submitRequestFormBody.EmployerPhone = this.requestForm.get("employerPhone").value;
        this.submitRequestFormBody.EmployerZip = this.requestForm.get("employerZip").value;
        this.submitRequestFormBody.EmployerFax = this.requestForm.get("employerFax").value;
  
        this.submitRequestFormBody.TrojanId = this.trojanId;
        this.submitRequestFormBody.CarrierName = this.requestForm.get("carrName").value;
        this.submitRequestFormBody.CarrierGroupNo = this.requestForm.get("carrGroupNo").value;
        this.submitRequestFormBody.CarrierCity = this.requestForm.get("carrCity").value;
        this.submitRequestFormBody.CarrierState = this.requestForm.get("carrState").value;
        this.submitRequestFormBody.CarrierPhone = this.requestForm.get("carrPhone").value;
        this.submitRequestFormBody.CarrCode = this.patientRequestFormDetails.CarrierCodeFromChariot;
        this.submitRequestFormBody.PrefProvider = this.requestForm.get("carrPref").value;
  
        this.submitRequestFormBody.DateNeeded = this.requestForm.get("dateNeeded").value;
        this.submitRequestFormBody.TimeNeeded = this.requestForm.get("timeNeeded").value;
        this.submitRequestFormBody.ReturnMethod = this.requestForm.get("returnBy").value;
  
        this.submitRequestFormBody.Submitted = this.requestForm.get("submitted").value;
        this.submitRequestFormBody.Remarks = this.requestForm.get("remarks").value;
  
        this.submitRequestFormBody.IsEligChecked = this.requestForm.get("reqCheckBoxes.reqElig").value;
        this.submitRequestFormBody.IsFaxChecked = this.requestForm.get("reqCheckBoxes.reqFax").value;
        this.submitRequestFormBody.IsTPChecked = this.requestForm.get("reqCheckBoxes.reqTP").value;
        this.submitRequestFormBody.IsPHChecked = this.requestForm.get("reqCheckBoxes.reqPH").value;

        this.submitRequestFormBody.ForManualPatient = false;
      }


      this.activeModal.close(this.submitRequestFormBody);
    }
  }

  cancelRequest() {
    this.activeModal.dismiss();
  }

  getTrojanHolidays() {
    this.dentifiAPIService.getTrojanHolidays().subscribe({
      next: holidays => {
        this.trojanHolidays = holidays;
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  getExistingRequestStatus(){
    
    this.dentifiAPIService.getExistingRequestStatus(parseInt(this.ttuniqueId),this.eligibilityElement.PatientId,this.isPri).subscribe({
      next: resp => {
        if(resp.isEligRequestPending){
          this.isEligRequestPending = true;
        }
        if(resp.isTPRequestPending){
          this.isTPRequestPending = true;
        }
      },
      error: err => {
        console.log(err);
      }
    });
  }
  getPatientRequestFormDetails(entity: PatientRequestFormBody) {
    if (entity) {
      this.dentifiAPIService.getPatientRequestFormInfo(entity).subscribe({
        next: rslt => {
          this.patientRequestFormDetails = rslt;
          //Determine which service request the patient is eligible for
          if (this.dentifiType.toUpperCase() == "DE") {
            this.canDoElig = true;
            this.canDoFax = false;
            this.canDoTP = false;
            this.canDoPH = false;
          }else {
            this.canDoElig = this.patientRequestFormDetails.CanDoElig;
            this.canDoFax = this.patientRequestFormDetails.CanDoFax;
            if (this.dentifiService.toUpperCase().includes("B")) {
              this.canDoTP = true;
              this.canDoPH = this.patientRequestFormDetails.CanDoPH;
            }
          }            
            // Determine min date needed
            let reqDate = this.getMinReqDate();
            this.requestForm = this.fb.group({
              subFName: [ this.patientRequestFormDetails.SubFName, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
              subLName: [this.patientRequestFormDetails.SubLName, [Validators.required,Validators.pattern('^[a-zA-Z- ]*$')]],
              subDOB: [ this.patientRequestFormDetails.SubDOB, [Validators.required, isValidDate, isFutureDate]],
              subZip: [this.patientRequestFormDetails.ZipCode, Validators.required],
              subSSNMemIdGroup: this.fb.group({
                subSSN: [this.patientRequestFormDetails.SSN, isInValidSSN],
                subMemId: [this.patientRequestFormDetails.MemberId, isInValidID]
              }, {validator: subSSNOrsubMembIdExists}),
              patFName: [this.eligibilityElement.FName, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
              patLName: [this.eligibilityElement.LName, [Validators.required,Validators.pattern('^[a-zA-Z- ]*$')]],
              patDOB: [this.eligibilityElement.DOB,[Validators.required, isValidDate, isFutureDate]],
              employerName: [this.patientRequestFormDetails.EmployerName],
              employerCity: [''],
              employerState: ['-1'],
              employerPhone: [this.patientRequestFormDetails.EmployerPhone, isInValidPhone],
              employerFax: [''],
              employerZip: [''],
              carrName: [this.patientRequestFormDetails.CarrierName],
              carrCity: [''],
              carrState: ['-1'],
              carrPhone: [this.patientRequestFormDetails.CarrierPhone, isInValidPhone],
              carrGroupNo: [this.patientRequestFormDetails.CarrierGroupNo],
              carrPref: ['U'],
              dateNeeded: [ reqDate.toString(), [Validators.required,isValidDate]],
              timeNeeded: ['',isInvalidTime],
              returnBy: ['FAX'],
              submitted: [''],
              remarks: [''],
              reqCheckBoxes: this.fb.group({
                reqElig: [{ value: false, disabled: (!this.canDoElig || this.isEligRequestPending) }],
                reqFax: [{ value: false, disabled: !this.canDoFax }],
                reqTP: [{ value: false, disabled: (!this.canDoTP || this.isTPRequestPending) }],
                reqPH: [{ value: false, disabled: !this.canDoPH }]
              }, {validator: atleastOneCheckboxChecked})
            });
          
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    } else {
      
      let reqDate = this.getMinReqDate();
      let formattedSubDOBArray = this.manualAppt.SubDOB.split(" ");
      let formattedSubDOB = formattedSubDOBArray[0];
      let formattedPatDOBArray = this.manualAppt.SubDOB.split(" ");
      let formattedPatDOB = formattedSubDOBArray[0];
      this.requestForm = this.fb.group({
        subFName: [ this.manualAppt.SubFName, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
        subLName: [this.manualAppt.SubLName,[Validators.required,Validators.pattern('^[a-zA-Z- ]*$')]],
        subDOB: [formattedSubDOB, [Validators.required, isValidDate, isFutureDate]],
        subZip: [this.manualAppt.SubZip, Validators.required],
        subSSNMemIdGroup: this.fb.group({
          subSSN: ['', isInValidSSN],
          subMemId: ['', isInValidID]
        }, {validator: subSSNOrsubMembIdExists}),
        patFName: [this.manualAppt.FName, [Validators.required,Validators.pattern('^[a-zA-Z ]*$')]],
        patLName: [this.manualAppt.LName, [Validators.required,Validators.pattern('^[a-zA-Z- ]*$')]],
        patDOB: [formattedPatDOB,[Validators.required, isValidDate,isFutureDate]],
        employerName: [this.manualAppt.EmployerName],
        employerCity: [''],
        employerState: ['-1'],
        employerPhone: [this.manualAppt.EmployerPhone, isInValidPhone],
        employerFax: [''],
        employerZip: [''],
        carrName: [this.manualAppt.CarrierName],
        carrCity: [''],
        carrState: ['-1'],
        carrPhone: ['', isInValidPhone],
        carrGroupNo: [this.manualAppt.GroupNo],
        carrPref: ['U'],
        dateNeeded: [ reqDate.toString(), [Validators.required,isValidDate]],
        timeNeeded: ['', isInvalidTime],
        returnBy: ['FAX'],
        submitted: [''],
        remarks: [''],
        reqCheckBoxes: this.fb.group({
          reqElig: [{ value: false, disabled: true }],
          reqFax: [{ value: false, disabled: true }],
          reqTP: [{ value: false, disabled: false }],
          reqPH: [{ value: false, disabled: false }]
        }, {validator: atleastOneCheckboxChecked})
      });
    }

  }
  getMinReqDate() {
    let reqDate = new Date();
    switch (reqDate.getDay()) {
      case 0: {
        reqDate.setDate(reqDate.getDate() + 1);
        break;
      }
      case 6: {
        reqDate.setDate(reqDate.getDate() + 2);
        break;
      }
      case 5: {
        reqDate.setDate(reqDate.getDate() + 3);
        break;
      }
      default: {
        reqDate.setDate(reqDate.getDate() + 1);
        break;
      }
    }
    let formattedDate = ((reqDate.getMonth() > 8) ? (reqDate.getMonth() + 1) : ('0' + (reqDate.getMonth() + 1))) + '/' + ((reqDate.getDate() > 9) ? reqDate.getDate() : ('0' + reqDate.getDate())) + '/' + reqDate.getFullYear()
    
    return formattedDate;
  }


  isSelectedDateInValid(target: string) {
      let selectedDate = new Date(target);
      let selectedDateInMillisec = selectedDate.getTime();
      let selectedDateDayOfWeek = selectedDate.getDay();
      //Checks if Selected Date Falls on a Trojan Holiday
      for (let i = 0; i < this.trojanHolidays.length; i++) {
          let value = this.trojanHolidays[i];
          if (selectedDateInMillisec == new Date(value).getTime()) {
              return true;
          }
      }
      //Checks if Selected Date Falls on Weekend. 
      if (selectedDateDayOfWeek == 0 || selectedDateDayOfWeek == 6) {
          return true;
      }
      //Check if Selected Date is not a past date
      let todaysDate = new Date();
      if (selectedDateInMillisec <= todaysDate.getTime()) {
        return true;
    }

    
    return false;
  
  }

  changeCarrState(e) {
    this.requestForm.get("carrState").setValue(e.target.value, {
      onlySelf: true
    });
  }

  changeEmployerState(e) {
    this.requestForm.get("employerState").setValue(e.target.value, {
      onlySelf: true
    });
  }


  populateSlashes(e,control){
	
    //To accomdate for backspacing, we detect which key was pressed - if backspace, do nothing:
    if(e.which !== 8) {	
      let cValue = control.value;
      var numChars = cValue.length;
      if(numChars === 2 || numChars === 5){
        var thisVal = cValue;
        thisVal += '/';
        control.setValue(thisVal);
      }
    }
  }

  populatePhoneSlashes(e,control){
	
    //To accomdate for spacing, we detect which key was pressed - if space, do nothing:
    if(e.which !== 32) {	
      let cValue = control.value;
      var numChars = cValue.length;
      if(numChars === 3 || numChars === 7){
        var thisVal = cValue;
        thisVal += ' ';
        control.setValue(thisVal);
      }
    }
  }

}
