import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { User } from 'src/app/Custom Datatypes/User';
import { SecurityService } from 'src/app/security/security.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {

  email: string = "";
  password: string = "";
  confirmPassword: string = "";
  faUser = faUser;
  faLock = faLock;
  faEnvelope = faEnvelope;
  Users: User[];
  ttuniqueId: number = parseInt(localStorage.getItem("ttuniqueId"));
  trojanAccount: string = localStorage.getItem("trojanAcctNumber");
  userName: string = localStorage.getItem("userName");
  newUsername: string = "";
  isAdmin: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("form-check-input") as HTMLCollectionOf<HTMLInputElement>;
  adminCheckboxChecked: boolean;
  isEditMode: boolean = false;
  selectedUsernameForEdit: string = "";
  isLoggedInUserAdmin: string = localStorage.getItem("isAdmin");

  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService, private modal: NgbModal, private securityService: SecurityService) { }

  ngOnInit() {
    this.securityService.getAllUsers(this.ttuniqueId).subscribe({
      next: response => {
        this.Users = response;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  createUser() {
    this.securityService.createUser(this.newUsername, this.password, this.confirmPassword, this.email, this.isAdmin[0].checked, this.trojanAccount).subscribe({
      next: response => {
        if (response.message) {
          if (response.message == 'User successfully added!') {
            alert(response.message);
            var modalDismssElement = document.getElementsByClassName("dismissAddUserModal") as HTMLCollectionOf<HTMLInputElement>;
            modalDismssElement[0].click();
            this.ngOnInit();
          } else {
            alert(response.message);
          }
        }

      },
      error: err => {
        console.log(err);
      }
    });
  }

  editUser(user: User) {
    var isAdminUser;
    if (this.isEditMode) {
      isAdminUser = this.adminCheckboxChecked;
    } else {
      isAdminUser = this.isAdmin[0].checked
    }
    this.securityService.editUser(this.newUsername, this.password, this.confirmPassword, this.email, isAdminUser, this.trojanAccount, this.selectedUsernameForEdit).subscribe({
      next: response => {
        if (response.message) {
          if (response.message == 'User successfully updated!') {
            alert(response.message);
            var modalDismssElement = document.getElementsByClassName("dismissAddUserModal") as HTMLCollectionOf<HTMLInputElement>;
            modalDismssElement[0].click();
            this.ngOnInit();
          } else {
            alert(response.message);
          }
        }

      },
      error: err => {
        console.log(err);
      }
    });
  }

  deleteUser(userName: string) {
    if (this.userName == userName) {
      alert("You cannot perform this action for the current user!");
      return;
    }
    else {
      if (confirm("Are you sure you want to delete this user?")) {
        this.securityService.deleteUser(userName).subscribe({
          next: response => {
            this.ngOnInit();
          },
          error: err => {
            console.log(err);
          }
        });
      }
    }

  }

  toggleAdmin(userName: string, makeAdmin: boolean) {
    this.securityService.toggleAdmin(userName, this.trojanAccount, makeAdmin).subscribe({
      next: response => {
        this.ngOnInit();
      },
      error: err => {
        console.log(err);
      }
    });
  }

  toggleTwoFA(userName: string, enableTwoFA: boolean) {
    this.securityService.toggleTwoFA(userName, this.trojanAccount, enableTwoFA).subscribe({
      next: response => {
        this.ngOnInit();
      },
      error: err => {
        console.log(err);
      }
    });
  }

  toggleLock(userName: string, mode: string, $event) {
    if (this.userName == userName) {
      alert("You cannot perform this action for the current user!");
      return;
    }
    else {
      if (mode == 'lock') {
        if (confirm("Are you sure you want to lock this user?")) {
          this.securityService.toggleLock(userName, true).subscribe({
            next: response => {
              this.ngOnInit();
            },
            error: err => {
              console.log(err);
            }
          });
        }else{
          $event.source.checked=false;
        }
      } else if (mode == 'unlock') {
        if (confirm("Are you sure you want to unlock this user?")) {
          this.securityService.toggleLock(userName, false).subscribe({
            next: response => {
              this.ngOnInit();
            },
            error: err => {
              console.log(err);
            }
          });
        }else{
          $event.source.checked=true;
        }
      }
    }
  }




  openAddUserModal(addUserModal: any, user: User) {
    if (user) {
      this.newUsername = user.UserName;
      this.email = user.Email.trim();
      this.adminCheckboxChecked = user.AdminLevel;
      this.isEditMode = true;
      this.selectedUsernameForEdit = user.UserName;
    } else {
      this.isEditMode = false;
    }
    let options: NgbModalOptions = {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    }
    this.modal.open(addUserModal, options).result.then(result => {
      this.email = "";
      this.password = "";
      this.confirmPassword = "";
      this.newUsername = "";
    },
      reason => {
        this.email = "";
        this.password = "";
        this.confirmPassword = "";
        this.newUsername = "";
      });

  }


  cancelRequest() {
    this.activeModal.close();
  }


  onLockChange($event, user) {
    if ($event.checked)
      this.toggleLock(user.UserName, 'lock', $event);
    else
      this.toggleLock(user.UserName, 'unlock', $event);
  }

  onAdminChange($event, user) {
    if ($event.checked)
      this.toggleAdmin(user.UserName, true);
    else
      this.toggleAdmin(user.UserName,false);
  }

  onTwoFAChange($event, user){
    this.toggleTwoFA(user.UserName,$event.checked);
  }

  getUserTFMethod(userTFMethod){
    if(userTFMethod.trim()=='') return 'Email';
    else return userTFMethod;
  }

}
