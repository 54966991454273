import { Component, Input, OnInit } from '@angular/core';
import { NgbModalOptions, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RemainingBalDownloadTime } from '../Custom Datatypes/RemainingBalDownloadTime';
import { DentifiApiService } from '../Services/dentifi-api.service';
import { EventEmitterRefreshRemainingBalanceService } from '../Services/event-emitter-refresh-remaining-balance.service';
import { SearchPlanComponent } from '../shared/search plan/search-plan.component';
import { RequestlogComponent } from '../shared/RequestLog/requestlog.component';
import { MetricsComponent } from '../shared/Metrics/metrics.component';
import { EventEmitterService } from '../Services/event-emitter.service';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { ExportApptComponent } from '../shared/export-appt/export-appt.component';
import { SecurityService } from '../security/security.service';

@Component({
  selector: 'app-nav-menu-appt',
  templateUrl: './nav-menu-appt.component.html',
  styleUrls: ['./nav-menu-appt.component.css']
})
export class NavMenuApptComponent implements OnInit {
  bellIcon = faBell;
  dentifiType: string = localStorage.getItem("dentifiType");
  pms: string = localStorage.getItem("PMS");
  pmsName: string = "";
  pmsVersion: string = "";
  lastDownloadDate: string = "";
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  remainingBalance: string = "";
  officeName: string = localStorage.getItem("officeName");
  officePhone: string = localStorage.getItem("officePhone");
  officeFax: string = localStorage.getItem("officeFax");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  clientServices: string = localStorage.getItem("clientServices");
  isAdmin: string = localStorage.getItem("isAdmin");
  elgHeader: string = "Eligibility";
  benHeader: string = "";
  apptHeader: string = "";
  faxHeader: string = "";
  matchHeader: string = "";
  balanceAndDownloadTime: RemainingBalDownloadTime = new RemainingBalDownloadTime();

  @Input() selectedPatID: any; //Patient's Chart number
  @Input() selectedApptId: any;
  @Input() isManual: any;
  @Input() selectedPatientId: number;
  @Input() showAlertIcon: boolean = false;
  dataNotReceivedOrOld: boolean;
  dataCurrent: boolean;
  selectedPat: any;

  twoFactorEnabled: boolean;
  twoFactorForAllUsers: boolean;
  twoFactorMethod: string;

  constructor(private modal: NgbModal, private dentifiAPIService: DentifiApiService, private eventServiceForNavAppt: EventEmitterRefreshRemainingBalanceService, private eventEmitterService: EventEmitterService, private securityService: SecurityService) { }

  ngOnInit() {
    if (this.eventServiceForNavAppt.subsVar == undefined) {
      this.eventServiceForNavAppt.subsVar = this.eventServiceForNavAppt.
        invokeApptNavMenuMethods.subscribe(() => {
          this.ngOnInit();
        });
    }




    //Set PMS name and Version
    let pmsInfoArray = this.pms.split(";");
    if (pmsInfoArray.length > 0) this.pmsName = pmsInfoArray[0];
    if (pmsInfoArray.length == 2) this.pmsVersion = pmsInfoArray[1];


    //Get Remaining Balance and Last Download Date
    this.dentifiAPIService.getRemainingBalanceAndDownloadDate(this.trojanAcct, parseInt(this.ttuniqueId)).subscribe({
      next: resp => {
        Object.assign(this.balanceAndDownloadTime, resp);
        this.remainingBalance = this.balanceAndDownloadTime.remainingBalance.toString();
        this.lastDownloadDate = this.balanceAndDownloadTime.lastDownloadDate;
        if (this.lastDownloadDate.includes("/")) {
          const datevalue = this.lastDownloadDate.split("/");
          const dateValueDay = datevalue[1].padStart(2, "0");
          const dateValueMonth = datevalue[0].padStart(2, "0");
          const dateValueYear = datevalue[2];
          this.lastDownloadDate = `${dateValueMonth}/${dateValueDay}/${dateValueYear}`;
        }
        //Process last data download date status info. 
        if (this.lastDownloadDate.length > 0) {
          let downloadDate = new Date(this.lastDownloadDate);
          let cutOffDate = new Date(new Date().setDate(new Date().getDate() - 3));

          if (downloadDate < cutOffDate) {
            this.dataNotReceivedOrOld = true;
            this.dataCurrent = false;
          } else {

            this.dataNotReceivedOrOld = false;
            this.dataCurrent = true;
          }
        } else {
          this.dataNotReceivedOrOld = true;
          this.dataCurrent = false;
        }

      },
      error: err => {
        console.log(err);
      }
    });




    //Determine what services the client has.
    if (this.clientServices.includes("F")) {
      this.apptHeader = "Fax";
    } else {
      this.apptHeader = "";
    }
    if (this.dentifiType.toUpperCase() == "DE") {
      this.apptHeader = "";
    }
    if (this.clientServices.includes("B")) {
      this.benHeader = "Benefits";
      if (this.clientServices.includes("T")) {
        this.matchHeader = "Auto Matching";
      } else {
        this.matchHeader = "Manual Matching";
      }
    } else {
      if (this.clientServices.includes("X")) {
        this.benHeader = "Custom";
      }
    }
    if (this.clientServices.includes("F")) {
      this.faxHeader = "Auto Fax";
    } else {
      this.faxHeader = "Manual Fax";
    }


    this.twoFactorEnabled = JSON.parse(localStorage.getItem("twoFactorEnabled"));
    this.twoFactorForAllUsers = JSON.parse(localStorage.getItem("twoFactorForAllUsers"));
    this.twoFactorMethod = localStorage.getItem("twoFactorMethod");

    if (this.twoFactorMethod.trim() == '') this.twoFactorMethod = 'Email';
  }
  showRequestForm() {
    //check patient is selected or not
    if (this.selectedPatID == null) {
      alert("Please select Patient first from grid");
      return;
    }
    //this.eventEmitterService.onSearchPlanClick(this.selectedPatID);

    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: true
    }
    let modalRef = this.modal.open(SearchPlanComponent, options);
    modalRef.componentInstance.selectedPatID = this.selectedPatID; //Patient's Chart
    modalRef.componentInstance.selectedPatientId = this.selectedPatientId;
    modalRef.componentInstance.selectedApptId = this.selectedApptId;
    modalRef.componentInstance.isManual = this.isManual;
    modalRef.result.then(result => {

    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }

  showRequestLog() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: true
    }
    let modalRef = this.modal.open(RequestlogComponent, options);
    modalRef.result.then(result => {

    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }

  showMetrics() {
    let options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    }
    let modalRef = this.modal.open(MetricsComponent, options);
    modalRef.result.then(result => {

    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }

  exportAppt() {
    let options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    }
    let modalRef = this.modal.open(ExportApptComponent, options);
    modalRef.result.then(result => {

    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }

  toggleTwoFA($event) {

    let enableTwoFA: boolean = $event.checked;
    let userName: string = localStorage.getItem("userName");
    let trojanAccount: string = localStorage.getItem("trojanAcctNumber");
    this.securityService.toggleAccountTwoFA(userName, trojanAccount, enableTwoFA).subscribe({
      next: response => {
        this.getClientInfo();

      },
      error: err => {
        console.log(err);
      }
    });
  }

  toggleTwoFAForAll($event) {

    let enableTwoFA: boolean = $event.checked;
    let userName: string = localStorage.getItem("userName");
    let trojanAccount: string = localStorage.getItem("trojanAcctNumber");
    this.securityService.toggleAccountTwoFAForAll(userName, trojanAccount, enableTwoFA).subscribe({
      next: response => {
        this.getClientInfo();

      },
      error: err => {
        console.log(err);
      }
    });
  }



  getClientInfo() {
    this.dentifiAPIService.getClientInfo(localStorage.getItem('userName'), localStorage.getItem("bearerToken")).subscribe({
      next: data => {

      },
      error: err => {
        console.log(err);
      }
    });
  }
}
