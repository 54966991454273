
import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
import { ManualPatientRow } from '../Custom Datatypes/ManualPatientRow';

    
@Injectable({    
  providedIn: 'root'    
})    
export class ManualApptBenEligEventEmiterService {    
    
  invokeBenfitEligTabsetComponentFunction = new EventEmitter();    
  subsVar: Subscription;    
    
  constructor() { }    
    
  onBenfitEligTabsetComponentButtonClick(eligElement: ManualPatientRow) {    
    this.invokeBenfitEligTabsetComponentFunction.emit(eligElement);    
  }    
}  

