import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParserFormatter } from 'src/app/appointment/appointment.component';
import { ApptRequest } from 'src/app/Custom Datatypes/ApptRequest';
import { TwoFAPopUpComponent } from 'src/app/security/2fa/2fa-popup/two-fa-pop-up.component';
import { AppUser } from 'src/app/security/app-user';
import { AppUserAuth } from 'src/app/security/app-user-auth';
import { SecurityService } from 'src/app/security/security.service';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';

@Component({
  selector: 'app-export-appt',
  templateUrl: './export-appt.component.html',
  styleUrls: ['./export-appt.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class ExportApptComponent implements OnInit {
  selectedFromDate: any;
  selectedToDate: any;
  startDate: any;
  endDate: any;
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  dentifiService: string = localStorage.getItem("clientServices");
  dentifiType: string = localStorage.getItem("dentifiType");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");

  processingMetrics: boolean = false;
  showError : boolean = false;
  isFromGreater : boolean = false;

  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService, private modal: NgbModal, private securityService: SecurityService) { }

  ngOnInit() {
    let d = new Date();
    
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    d.setDate(d.getDate() + 14);
    this.endDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedFromDate = this.startDate;
    this.selectedToDate = this.endDate;
  }
  
//check From Date Should be less than To date
checkValidDate(){
  const fromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
  const toDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
  if(fromDate > toDate)
  {
    this.isFromGreater = true;
  }
  else
  {
    this.isFromGreater = false;
  }
}
onDateChange(){
  this.checkValidDate();
}

  
  incrementCalendarDayByOne(type: string) {
    if (type === 'from') {
      let d = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }
    this.checkValidDate();
  }

  decrementCalendarDayByOne(type: string) {

    if (type === 'from') {
      let d = new Date(this.selectedToDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }
    this.checkValidDate();
  }


  cancelRequest() {
    this.activeModal.close();
  }


  send2FA(){
    let requestObj=new AppUser();
    requestObj.userName=localStorage.getItem("userName");
    requestObj.password=localStorage.getItem("userName");
    this.securityService.sendTwoFA(requestObj).subscribe({
      next: resp => {
        if(resp.success==false)
        {
          alert("2-Factor Authentication Failed");
          return;
        }
        
        this.open2FA(resp);
      },
      error: err => {
       
      }
    }
    );
  }

  open2FA(resp) {
    let options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    }
    let modalRef = this.modal.open(TwoFAPopUpComponent, options);
    modalRef.componentInstance.userMail = btoa(resp.userEmail);
    modalRef.componentInstance.userName = resp.userName;

    modalRef.result.then(result => {
      if(result==true){
        //2fa success
        this.exportPatAppt();
      }
      else{
        //show 2fa fails, try again
        this.showError = true;
      }
    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }


  exportPatAppt(): void {

    var formattedFromDate = `${this.selectedFromDate.month}/${this.selectedFromDate.day}/${this.selectedFromDate.year}`;
    var formattedToDate = `${this.selectedToDate.month}/${this.selectedToDate.day}/${this.selectedToDate.year}`;

    let apptRequest = new ApptRequest(parseInt(this.ttuniqueId), formattedFromDate, this.dentifiType, this.trojanAcct, this.dentifiService, "", formattedToDate,"",true);


    this.dentifiAPIService.exportPatAppointments(apptRequest).subscribe({
      next: res => {
        


        const blob = new Blob([res.body], { type: 'text/csv' }); // you can change the type

        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blob);
        a.download = "appointments.csv";
        a.click();
        a.remove();

      },
      error: err => {
        
        // this.errorMessage = err;
        //   console.log(this.errorMessage);
      }
    });
  }
}
