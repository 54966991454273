import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchedPlan } from 'src/app/Custom Datatypes/SearchedPlan';
import { SearchPlanRequestBody } from 'src/app/Custom Datatypes/SearchPlanRequestBody';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { EventEmitterService } from '../../Services/event-emitter.service';
import { LinkPlanRequest } from '../../Custom Datatypes/LinkPlansRequest';
function atleastFieldProvided(c: AbstractControl): {[key: string]: boolean} | null {
  let trojanId = c.get("trojanId");
  let empName = c.get("employerName");
  let policNo = c.get("policyNo");

  if (trojanId.value.trim() == "" && empName.value.trim() == "" && policNo.value.trim() == "") {
    return { "noInputProvided": true}
  }
  return null;
}


@Component({
  selector: 'app-search-plan',
  templateUrl: './search-plan.component.html',
  styleUrls: ['./search-plan.component.css']
})
export class SearchPlanComponent implements OnInit {
  ttuniqueId: number = parseInt(localStorage.getItem("ttuniqueId"));
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  errorMessage: string;
  requestForm: FormGroup;
  plans: SearchedPlan[] = [];
  trojanPlanInnerHtml: SafeHtml = "";
 @Input() selectedPatID; //Patient's Chart
 @Input() selectedApptId;
 @Input() selectedPatientId;
 @Input() isManual;
 isPri:string="Y";
 noResultFound : boolean = false;
 
  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService,private fb: FormBuilder, private domSanitizer: DomSanitizer,private eventEmitterService : EventEmitterService) { }
 
  ngOnInit() {
    // this.eventEmitterService.
    // invokeSearchPlanComponentFunction.subscribe((patID: any) => {
    //   
    //     this.selectedPatID = patID;
    //   });

    this.requestForm = this.fb.group({
      trojanId: [''],
      employerName: [''],
      policyNo: [''],

    },{validator:atleastFieldProvided});
  }

/*   getPatientIdsForToday() {
    this.dentifiAPIService.getJustAppointmentver2ApptsForToday(this.ttuniqueId).subscribe({
      next: patientIds => {
        for (let x = 0; x<patientIds.length; x++) {
          let splitChartId = patientIds[x].split(";");
          this.patientCharts.push(splitChartId[0]);
          this.patientIds.push(splitChartId[1]);
        }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  } */

  searchPlans() {
    let request = new SearchPlanRequestBody();
    request.TrojanId = this.requestForm.get("trojanId").value;
    request.EmployerName = this.requestForm.get("employerName").value;
    request.PolicyNo = this.requestForm.get("policyNo").value;

    this.dentifiAPIService.getTrojanPlans(request).subscribe({
      next: plans => {
          this.plans = plans;
          if(this.plans.length == 0){
            this.noResultFound = true;
          }
          else{
            this.noResultFound = false;
          }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    }); 
  }

  linkPlan(trojanId:string){
    let planRequests: LinkPlanRequest[] = [];
      //for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
        if (trojanId !="") {
          let planRequest = new LinkPlanRequest;
          //let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
          planRequest.TrojanId = trojanId;
          planRequest.PatChart = this.selectedPatID;
          planRequest.IsPri = this.isPri;
          planRequest.PatientId = this.selectedPatientId;
          planRequest.TrojanAcctNumber = this.trojanAcct;
          planRequest.TTUniqueId = this.ttuniqueId;
          planRequest.ApptId = this.selectedApptId;
          planRequest.IsManual = this.isManual;
          planRequests.push(planRequest);

        }
      //}

      this.dentifiAPIService.linkSelectedPlans(planRequests).subscribe({
        next: result => {
          //this.processAndDisplayAppointments();
          //this.currentSelectedPatient = undefined;
          alert(result.message)
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
  }

  downloadPlan(trojanId: string) {
    this.dentifiAPIService.getStaticHTMLBSPlan(trojanId,true).subscribe({
      next: plan => {
        this.replaceTPHtml(plan);
      },
      error: err => {
        this.errorMessage = err;
        console.log("error occured getting TP: " + this.errorMessage);
      }
    }); 
  }

  private replaceTPHtml(innerHTML: string): void {
      this.trojanPlanInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);      
  }

  backToSearch() {
    this.trojanPlanInnerHtml = null;
  }

  submit() {
    this.activeModal.close(/**return data back to main component that opend this modal */);
  }
  cancelRequest() {
    this.activeModal.close();
  }

}
