import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PasswordValidator } from './PasswordValidator';


  
    @NgModule({
      imports: [
        CommonModule
        //RouterModule.forChild(routes),
        //FontAwesomeModule,
        //FormsModule
        
      ],
      declarations: [PasswordValidator
        //MustMatchDirective,
        //PasswordValidator
    ],
      exports:[PasswordValidator]
    })  
    export class PasswordValidatorModule {
    }