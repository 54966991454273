import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { AppUserAuth } from './app-user-auth';
import { AppUser } from './app-user';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, map, catchError } from 'rxjs/operators';
import { SpinnerService } from '../shared/Spinner/SpinnerService';
import { User } from '../Custom Datatypes/User';

//const ACCOUNT_API_URL = "https://localhost:44360/api/Account";
//const ACCOUNT_API_URL = "https://beta.Dentifi.com/api/Account";
const ACCOUNT_API_URL = "https://www.dentifi.com/api/Account";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  securityObject: AppUserAuth = new AppUserAuth();

  constructor(private http: HttpClient, private spinnerService: SpinnerService) { }

  createUser(userName:string, password: string, confirmPassword: string, email: string, isAdmin: boolean, trojanAcctNo: string): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "CreateUser",{"Username":userName, "Password": password, "ConfirmPassword": confirmPassword, "Email": email, "IsAdmin": isAdmin, "TrojanAcctNumber":trojanAcctNo},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  editUser(newUserName:string, password: string, confirmPassword: string, email: string, isAdmin: boolean, trojanAcctNo: string, oldUsername: string): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "EditeUser",{"NewUsername":newUserName, "Password": password, "ConfirmPassword": confirmPassword, "Email": email, "IsAdmin": isAdmin, "TrojanAcctNumber":trojanAcctNo, "OldUsername":oldUsername},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }
  
  deleteUser(userName:string): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "DeleteUser",{"Username":userName},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  getAllUsers(ttuniqueId:number): Observable<User[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<User[]>(ACCOUNT_API_URL + "/" + "GetAllUsers",ttuniqueId,{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  toggleAdmin(userName: string, trojanAcct: string, adminLevel: boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "ToggleAdmin",{TrojanAccount:trojanAcct, UserName: userName, AdminLevel: adminLevel},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );    
  }


  toggleTwoFA(userName: string, trojanAcct: string, enableTwoFA: boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "Toggle2FA",{TrojanAccount:trojanAcct, UserName: userName, TwoFAEnabled: enableTwoFA},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );    
  }

  toggleAccountTwoFA(userName: string, trojanAcct: string, enableTwoFA: boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "UpdateAccount2FA",{TrojanAccount:trojanAcct, UserName: userName, TwoFAEnabled: enableTwoFA},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );    
  }

  toggleAccountTwoFAForAll(userName: string, trojanAcct: string, enableTwoFA: boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "UpdateAccount2FAForAllUser",{TrojanAccount:trojanAcct, UserName: userName, TwoFAEnabled: enableTwoFA},{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );    
  }

  toggleLock(userName: string, lock: boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "ToggleLock",{UserName: userName, Lock:lock },{ headers:httpOptions }).pipe(
      catchError(this.handleError)
    );    
  }

  resetSecurityObject(): void {
    this.securityObject.userName = "";
    this.securityObject.bearerToken = "";
    this.securityObject.isAuthenticated = false;
    this.securityObject.ttuniqueId = 0;
    this.securityObject.trojanAcctNumber = "";
    this.securityObject.dentifiType = "";
    this.securityObject.pms = "";
    this.securityObject.clientServices  = "";
    this.securityObject.trialInformation = null;
    this.securityObject.resetPassword = false;

    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userName");
  }

  login(entity: AppUser): Observable<AppUserAuth> {
    this.resetSecurityObject();
    //Note: don't create a new AppUserAuth object because that destroys all references to object. 
    this.spinnerService.requestStarted();
    return this.http.post<AppUserAuth>(ACCOUNT_API_URL + "/" + "Authenticate" , entity, httpOptions).pipe(
      tap(resp => {
        if (resp.resetPassword) {
          this.spinnerService.requestEnded();
        } else if (!resp.isEnabled) {
          this.spinnerService.requestEnded();
        }        
        else {
          Object.assign(this.securityObject, resp);
          localStorage.setItem("bearerToken", this.securityObject.bearerToken);
          localStorage.setItem("userName",  this.securityObject.userName);
          this.spinnerService.requestEnded();
        }

      }),
      catchError(this.handleError)
    );


  }

  logout(): void {
    this.resetSecurityObject();
  }


  sendForgotPasswordEmail(username: string): Observable<any> {
    this.spinnerService.requestStarted();
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "ForgotPassword" ,{"username": username}, httpOptions).pipe(
      map(result => {  this.spinnerService.requestEnded(); return result; }, error => {return error;})
    );
  }

  confirmEmail(id: string,token:string,u:string): Observable<any> {
    this.spinnerService.requestStarted();
    return this.http.get<any>(ACCOUNT_API_URL + "/" + "Emailverified?id="+id+"&token="+token+"&u="+u).pipe(
      catchError(this.handleError)
    );
  }

  resetPassword(encryptedusername: string, password: string, confirmpassword: string, token: string): Observable<any> {
    this.spinnerService.requestStarted();
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "ResetPassword", {"encryptedusername": encryptedusername, "password": password, "confirmpassword": confirmpassword, "token": token}, httpOptions).pipe(tap(data => this.spinnerService.requestEnded()));
  }

  loginTwoStep(email: string, userName:string,token: string, isForLogin:boolean=true): Observable<any> {
    this.spinnerService.requestStarted();
    return this.http.post<any>(ACCOUNT_API_URL + "/" + "LoginTwoFactor", {"email": email, "encryptedUsername":userName, "twoFactorCode": token, "isForLogin" : isForLogin}, httpOptions).pipe(tap(data => this.spinnerService.requestEnded()));
  }

  private handleError(err: HttpErrorResponse) {
    
    //Later we may want to send the error to some remote logging infrastructure instead of just logging it to the console.
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful reponse code. The response body may contain clues as to waht went wrong.
      if (err.status === 401) {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ttuniqueId");
        localStorage.removeItem("trojanAcctNumber");
        localStorage.removeItem("dentifiType");
        localStorage.removeItem("clientServices");
        localStorage.removeItem("userName");
        localStorage.removeItem("avaId");
        localStorage.removeItem("avap");
        localStorage.removeItem("avaPId");
        localStorage.removeItem("avaPp");
        localStorage.removeItem("PMS");
        localStorage.removeItem("officeName");
        localStorage.removeItem("officePhone");
        localStorage.removeItem("officeFax");
        localStorage.removeItem("isAdmin");
        window.location.href = "/login";
      }

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`
    }

    console.log(errorMessage);
    return throwError(errorMessage);
  }


  sendTwoFA(entity: AppUser): Observable<AppUserAuth> {
     
    return this.http.post<AppUserAuth>(ACCOUNT_API_URL + "/" + "SendTwoFA" , entity, httpOptions).pipe(
      tap(resp => {
        
      }),
      catchError(this.handleError)
    );


  }
}
