import { Component, Injectable, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Metrics } from 'src/app/Custom Datatypes/Metric';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if(!date){
      return '';
    }else{
      return date.month.toString().padStart(2,"0")+ this.DELIMITER + date.day.toString().padStart(2,"0") + this.DELIMITER + date.year;
    }
  }
}

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class MetricsComponent implements OnInit {
  selectedFromDate: any;
  selectedToDate: any;
  startDate: any;
  endDate: any;
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  metric: Metrics;
  processingMetrics: boolean = false;
  isFromGreater ; boolean = false;

  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService) { }

  ngOnInit() {
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate()}
    this.endDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedFromDate = this.startDate;
    this.selectedToDate = this.endDate;
  }

  isDateRangeValid(){
    const fromDate = new Date(this.selectedFromDate.year,this.selectedFromDate.month - 1,this.selectedFromDate.day);
    const toDate = new Date(this.selectedToDate.year,this.selectedToDate.month - 1,this.selectedToDate.day);
    if(fromDate>toDate)
    {
      this.isFromGreater = true;
    }else
    {
      this.isFromGreater = false;
    }
  }


  incrementCalendarDayByOne(type:string) {
    if (type === 'from') {
      let d = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }
    this.isDateRangeValid();
  }

  decrementCalendarDayByOne(type:string) {

    if (type === 'from') {
      let d = new Date(this.selectedToDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }
    this.isDateRangeValid();
  }

  getMetricInfo() {
    this.metric = undefined;
    this.processingMetrics = true;
    var formattedFromSelectedDate = `${this.selectedFromDate.month}/${this.selectedFromDate.day}/${this.selectedFromDate.year}`;
    var formattedToSelectedDate = `${this.selectedToDate.month}/${this.selectedToDate.day}/${this.selectedToDate.year}`;

    this.dentifiAPIService.getMetrics(parseInt(this.ttuniqueId),formattedFromSelectedDate,formattedToSelectedDate).subscribe({
      next: metric => {
        this.metric = metric;
        this.processingMetrics = false;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  cancelRequest() {
    this.activeModal.close();
  }
}
