import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';    
import { PatientRow } from '../Custom Datatypes/PatientRow';
    
@Injectable({    
  providedIn: 'root'    
})    
export class EventEmitterService {    
    
  invokeBenfitEligTabsetComponentFunction = new EventEmitter();    
  invokeSearchPlanComponentFunction = new EventEmitter(); 
  constructor() { }    
    
  onBenfitEligTabsetComponentButtonClick(eligElement: PatientRow) {    
    this.invokeBenfitEligTabsetComponentFunction.emit(eligElement);    
  } 
  
  onSearchPlanClick(PatID: any) {    
    this.invokeSearchPlanComponentFunction.emit(PatID);    
  } 
}  
