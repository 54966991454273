import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { faUserCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { RequestLog } from 'src/app/Custom Datatypes/RequestLog';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AppointmnetWithNoMemberId } from '../../Custom Datatypes/AppointmnetWithNoMemberId';
import { LinkPlanRequest } from 'src/app/Custom Datatypes/LinkPlansRequest';
import { CustomDateParserFormatter } from 'src/app/appointment/appointment.component';


@Component({
  selector: 'app-requestlog',
  templateUrl: './requestlog.component.html',
  styleUrls: ['./requestlog.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ]
})
export class RequestlogComponent implements OnInit {
  faUser = faUserCircle;
  faSearch = faSearch;
  userName: string = "";
  selectedFromDate: any;
  selectedToDate: any;
  startDate: any;
  endDate: any;
  logs: RequestLog[];
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  selectedSearchByFilter: string = "All";
  filter: FormControl = new FormControl('');
  searchByList: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName("searchList") as HTMLCollectionOf<HTMLElement>;
  logs$: Observable<any[]>;
  currentSelectedLog;
  reLinkPlansOld: any;
  trojanId: string;
  selectedPatID: string;
  //trojanAcct: string;
  isPri: string;
  RowID: number;
  noRecordFound : boolean  = false;
  isFromEmpty  :boolean = false;
  isToEmpty : boolean = false;
  isFromGreater : boolean = false;
  //processingLinkPlan : boolean = false;
  historyData :string;

  //isOlderDateNeeded = true;
  reLinkPlans: any;
  PatID: number;
  errorMessage: any;
  notifications: AppointmnetWithNoMemberId[] = [];
  //showAlertIcon: boolean;
  formattedSelectedDate: string;
  selectedDate: any;
  isCurrentTabDisabled = true;
  isOldTabDisabled = true;
  isMissingDataTabDisabled = true;
  dentifiType: string;

  constructor(private dentifiAPIService: DentifiApiService) {
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedDate = this.startDate;
  }

  ngOnInit() {

    this.userName = localStorage.getItem("userName");
    this.dentifiType = localStorage.getItem("dentifiType");
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: 1 }
    this.endDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedFromDate = this.startDate;
    this.selectedToDate = this.endDate;


    //get relink queue data  
    let sDate = new Date();
    let eDate = new Date();
    eDate.setDate(sDate.getDate() + 14);
    //get relink queue for current appt
    this.getReLinkQueue(sDate, eDate, false);
    //get relink queue for old appt
    this.getReLinkQueue(sDate, eDate, true);
    this.getPatMissingData();
  }

  
 //check From date should be less than To date
 checkValidDate(){
  const toDate = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
  const fromDate = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);

  if(fromDate>toDate)
  {
    this.isFromGreater = true;
  }else{
    this.isFromGreater = false;
  }
 }

  //Check empty date field
  isDateFieldEmpty(){
    if(!this.selectedFromDate)
    {
      this.isFromEmpty = true;
    }else{
      this.isFromEmpty = false;
    }
    if(!this.selectedToDate)
    {
      this.isToEmpty = true;
    }else{
      this.isToEmpty = false;
    }
  }


  incrementCalendarDayByOne(type: string) {
    if (type === 'from') {
      let d = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }
  }

  decrementCalendarDayByOne(type: string) {
    if (type === 'from') {
      let d = new Date(this.selectedFromDate.year, this.selectedFromDate.month - 1, this.selectedFromDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedFromDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    } else if (type === 'to') {
      let d = new Date(this.selectedToDate.year, this.selectedToDate.month - 1, this.selectedToDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedToDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    }

  }
  getRequestLogs() {
    this.isDateFieldEmpty();
    var formattedFromSelectedDate = `${this.selectedFromDate.month}/${this.selectedFromDate.day}/${this.selectedFromDate.year}`;
    var formattedToSelectedDate = `${this.selectedToDate.month}/${this.selectedToDate.day}/${this.selectedToDate.year}`;

    this.dentifiAPIService.getRequestLogs(parseInt(this.ttuniqueId), formattedFromSelectedDate, formattedToSelectedDate).subscribe({
      next: logs => {
        this.logs = logs;
        if(this.logs.length == 0)
        {
          this.noRecordFound = true;
        }
        else
        {
          this.noRecordFound = false;
        }

        this.logs$ = of(this.logs);
        this.logs$ = this.filter.valueChanges.pipe(
          startWith(''),
          map(text => this.search(text)));
      },
      error: err => {
        console.log(err);
      }
    });
    this.checkValidDate();
  }

  getReLinkQueue(startDate, endDate, isOlderDataNeeded) {
    this.dentifiAPIService.getReLinkQueue(parseInt(this.ttuniqueId), startDate, endDate, isOlderDataNeeded).subscribe({
      next: data => {
        if (isOlderDataNeeded) {
          this.reLinkPlansOld = data;
          if (data.length > 0) {
            this.isOldTabDisabled = false;
          }
        } else {
          this.reLinkPlans = data;
          if (data.length > 0) {
            this.isCurrentTabDisabled = false;
          }
        }

      },

      error: err => {
        console.log(err);
      }
    });
  }
 
  openHistoryData(patientId:string){
  
    this.dentifiAPIService.getPHPdf(patientId,this.ttuniqueId).subscribe({
    next: response => {
      if(response == "")
      {
        alert("Patient Does not have History Data");
        return;
      }
      this.historyData=response;
      setTimeout(() => {
        document.getElementById('btnPrintHistory').click();
      }, 0); 
    },
    error: err => {
      this.errorMessage = err;
      console.log(this.errorMessage);
    }
  });
}

  search(text: string): RequestLog[] {

    // let searchByFilter = this.selectedSearchByFilter;
    const term = text.toLowerCase();
    let filteredAPPTs;
    // if (searchByFilter == "Pri E" || searchByFilter == 'UTP' || searchByFilter == 'PI' || searchByFilter == 'E') {

    //   if (searchByFilter == 'UTP' || searchByFilter == 'PI') {
    //     filteredAPPTs = this.logs.filter(log => {
    //       return log.EligStatus.toLowerCase() == searchByFilter.toLocaleLowerCase();
    //     });
    //   } else if (searchByFilter == 'E') { //return both primary and secondary
    //     filteredAPPTs = this.logs.filter(log => {
    //       return log.EligStatus != '' || log.EligStatus != '';
    //     });
    //   } else {
    //     filteredAPPTs = this.logs.filter(log => {
    //       return log.EligStatus != '';
    //     });
    //   }



    // } else if (searchByFilter == "Sec E") {
    //   filteredAPPTs = this.logs.filter(log => {
    //     return log.EligStatus != '';
    //   });

    // } else {
    //   filteredAPPTs = this.logs;
    // }

    filteredAPPTs = this.logs;

    //apply search text if not empty else return filtered based on dropdown selection
    //Search Chart, F Name, L Name, PRI TP, SEC TP columns
    if (term != '') {
      return filteredAPPTs.filter(log => {
        return log.PatID.toLowerCase().includes(term)
          || log.FName.toLowerCase().includes(term)
          || log.LName.toLowerCase().includes(term)
          || log.EligStatus.toLowerCase().includes(term)
          ;
      });
    } else {
      return filteredAPPTs;
    }

  }
  onClickReLinkPlans(objReLinkPlan: any, calledFromOlder) {
    
    //this.processingLinkPlan = true;
    let planRequests: LinkPlanRequest[] = [];
    //   //for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
    if (objReLinkPlan.TrojanID != "") {
      let planRequest = new LinkPlanRequest;
      //let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
      planRequest.TrojanId = objReLinkPlan.TrojanID;
      planRequest.PatChart = objReLinkPlan.Chart;
      planRequest.IsPri = objReLinkPlan.IsPrimary;
      planRequest.PatientId = objReLinkPlan.PatientID;
      planRequest.TrojanAcctNumber = this.trojanAcct;
      planRequest.IsManual = objReLinkPlan.IsManualPatient;
      planRequest.TTUniqueId = parseInt(this.ttuniqueId);
      planRequest.RowID = objReLinkPlan.RowID;
      planRequests.push(planRequest);
    }


    this.dentifiAPIService.reLinkPlans(planRequests).subscribe({
      next: result => {

      //get relink queue data  
      let sDate = new Date();
      let eDate = new Date();
      eDate.setDate(sDate.getDate() + 14);
      
      if(calledFromOlder){
        //get relink queue for old appt
        this.getReLinkQueue(sDate, eDate, true);
        
      }else{
        //get relink queue for current appt
        this.getReLinkQueue(sDate, eDate, false);
      }
      
     // this.processingLinkPlan = false;

        alert(result.message)
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  onClickLinkAll(objLinkAllPlan, calledFromOlder) {
    let planRequests: LinkPlanRequest[] = [];
    for (var x = 0; x < objLinkAllPlan.length; x++) {

      //   //for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
      if (objLinkAllPlan[x].TrojanID != "") {
        let planRequest = new LinkPlanRequest;
        //let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
        planRequest.TrojanId = objLinkAllPlan[x].TrojanID;
        planRequest.PatChart = objLinkAllPlan[x].Chart;
        planRequest.IsPri = objLinkAllPlan[x].IsPrimary;
        planRequest.PatientId = objLinkAllPlan[x].PatientID;
        planRequest.TrojanAcctNumber = this.trojanAcct;
        planRequest.IsManual = objLinkAllPlan[x].IsManualPatient;
        planRequest.TTUniqueId = parseInt(this.ttuniqueId);
        planRequest.RowID = objLinkAllPlan[x].RowID;
        planRequests.push(planRequest);
      }

    }
    this.dentifiAPIService.reLinkPlans(planRequests).subscribe({
      next: result => {

        //get relink queue data  
      let sDate = new Date();
      let eDate = new Date();
      eDate.setDate(sDate.getDate() + 14);
      
      if(calledFromOlder){
        //get relink queue for old appt
        this.getReLinkQueue(sDate, eDate, true);
        
      }else{
        //get relink queue for current appt
        this.getReLinkQueue(sDate, eDate, false);
      }
        alert(result.message)
        // }
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });


  }


  getPatMissingData() {
    // this.userName = localStorage.getItem("userName");

    //if (!this.dontShowAlert) {
    //if(!this.showAlertIcon){
    this.formattedSelectedDate = `${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;

    this.dentifiAPIService.getNotifications(parseInt(this.ttuniqueId), this.formattedSelectedDate).subscribe({
      next: notificaitons => {
        if (notificaitons.length > 0) {
          this.notifications = notificaitons;
          this.isMissingDataTabDisabled = false;
          //this.showAlertIcon=true;
          //dont show popup now, show bell icon on menu so user can understand there is some notification
          //this.showClientNotification();
        } else
            this.isMissingDataTabDisabled = true;
      },
      error: err => {
        console.log(err);
      }
    });
    //}

  }




  cancelRequest() {
    //this.activeModal.close();
  }

  openFax(filePath: string, faxDetId: string, patientId: number) {
    this.dentifiAPIService.getPdfFax(filePath, faxDetId, this.trojanAcct, patientId).subscribe({
      next: response => {

        var file = new Blob([response], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, 'FAXDetails', 'menubar=1,resizable=1,width=850,height=550')
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

}

