import { Injectable, SecurityContext } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { SecurityService } from '../security/security.service';
import { EligibilityElement } from '../Custom Datatypes/EligibilityElement';
import { ApptRequest } from '../Custom Datatypes/ApptRequest';
import { PatientSubscriberEligBen } from '../Custom Datatypes/PatientSubscriberEligBen';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientSubscriberInfoRequest } from '../Custom Datatypes/PatientSubscriberInfoRequest';
import { SpinnerService } from '../shared/Spinner/SpinnerService';
import { Clinic } from '../Custom Datatypes/Clinic';
import { PatientRequestInfo } from '../Custom Datatypes/PatientRequestInfo';
import { PatientRequestFormBody } from '../Custom Datatypes/PatientRequestFormBody';
import { SubmitRequestFormBody } from '../Custom Datatypes/SubmitRequestFormBody';
import { SearchPlanRequestBody } from '../Custom Datatypes/SearchPlanRequestBody';
import { SearchedPlan } from '../Custom Datatypes/SearchedPlan';
import { MatchPlanBodyRequest } from '../Custom Datatypes/MatchPlanBodyRequest';
import { ManualAppointments } from '../Custom Datatypes/ManualAppointments';
import { Payer } from '../Custom Datatypes/Payer';
import { EligibilityRequestFormBody } from '../Custom Datatypes/EligibilityRequestFormBody';
import { DeleteManualPatientRequest } from '../Custom Datatypes/DeleteManualPatientRequest';
import { ClientNetwork } from '../Custom Datatypes/ClientNetwork';
import { RemainingBalDownloadTime } from '../Custom Datatypes/RemainingBalDownloadTime';
import { LinkPlanRequest } from '../Custom Datatypes/LinkPlansRequest';
import { AppUserAuth } from '../security/app-user-auth';
import { RequestLog } from '../Custom Datatypes/RequestLog';
import { VerifiedManuallyRequest } from '../Custom Datatypes/VerifiedManaullyRequest';
import { Metrics } from '../Custom Datatypes/Metric';
import { AppointmnetWithNoMemberId } from '../Custom Datatypes/AppointmnetWithNoMemberId';


//const DENTIFI_API_URL = "https://localhost:44360/api/Dentifi"; 
//const DENTIFI_API_URL = "https://beta.Dentifi.com/api/Dentifi";
const DENTIFI_API_URL = "https://www.dentifi.com/api/Dentifi";

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};


@Injectable({
  providedIn: 'root'
})
export class DentifiApiService {
  avaId: string = localStorage.getItem("avaId");
  avap: string = localStorage.getItem("avap");
  avaPId: string = localStorage.getItem("avaPId");
  avaPp: string = localStorage.getItem("avaPp");
  constructor(private http: HttpClient, private securityService: SecurityService, private domSanitizer: DomSanitizer, private spinnerService: SpinnerService) {
   }
  private bearerToken: string = localStorage.getItem('bearerToken');


  getAppointments(entity: ApptRequest): Observable<EligibilityElement[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    this.spinnerService.requestStarted();
    return this.http.post<EligibilityElement[]>(DENTIFI_API_URL + "/" + "GetAppointments", entity, { headers: httpOptions }).pipe(
      tap(data => this.spinnerService.requestEnded()),
      catchError(this.handleError)
    );
  }

  exportPatAppointments(entity: ApptRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    
    
    this.spinnerService.requestStarted();
    return this.http.post(DENTIFI_API_URL + "/" + "ExportPatAppointments", entity, { headers: httpOptions, observe: 'response', responseType:'blob'}).pipe(
      tap(data =>  this.spinnerService.requestEnded())
      //,
      //catchError(this.handleError)
    );
  }

  getClientInfo(username: string, bearkerToken:string): Observable<AppUserAuth> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + bearkerToken);
    this.spinnerService.requestStarted();
    return this.http.post<AppUserAuth>(DENTIFI_API_URL + "/" + "GetClientInfo", {Username:username}, { headers: httpOptions }).pipe(
      tap(data => {
        
        //Manaully set demo accounts.
        if (username == '000004' || username == 'DEDemo') {
          localStorage.setItem("dentifiType", "DE");
          localStorage.setItem("clientServices", "E 0");   
          localStorage.setItem("ttuniqueId", data.ttuniqueId.toString());
          localStorage.setItem("trojanAcctNumber", data.trojanAcctNumber);
          localStorage.setItem("avaId", data.avaId);
          localStorage.setItem("avap", data.avap);
          localStorage.setItem("avaPId", data.avaPId);
          localStorage.setItem("avaPp", data.avaPp);
          localStorage.setItem("PMS", data.pms);
          localStorage.setItem("officeName", data.officeName);
          localStorage.setItem("officePhone", data.officePhone);
          localStorage.setItem("officeFax", data.officeFax);
          localStorage.setItem("isAdmin", data.isAdmin.toString()); 
          
          localStorage.setItem("twoFactorEnabled", data.twoFactorEnabled.toString());
          localStorage.setItem("twoFactorForAllUsers", data.twoFactorForAllUsers.toString());
          localStorage.setItem("twoFactorMethod", data.twoFactorMethod);
        } else if(username == '000005' || username == 'DTDemo') {
          localStorage.setItem("dentifiType", "DT");
          localStorage.setItem("clientServices", "E F B R T 0");   
          localStorage.setItem("ttuniqueId", data.ttuniqueId.toString());
          localStorage.setItem("trojanAcctNumber", data.trojanAcctNumber);
          localStorage.setItem("avaId", data.avaId);
          localStorage.setItem("avap", data.avap);
          localStorage.setItem("avaPId", data.avaPId);
          localStorage.setItem("avaPp", data.avaPp);
          localStorage.setItem("PMS", data.pms);
          localStorage.setItem("officeName", data.officeName);
          localStorage.setItem("officePhone", data.officePhone);
          localStorage.setItem("officeFax", data.officeFax);
          localStorage.setItem("isAdmin", data.isAdmin.toString());

          localStorage.setItem("twoFactorEnabled", data.twoFactorEnabled.toString());
          localStorage.setItem("twoFactorForAllUsers", data.twoFactorForAllUsers.toString());
          localStorage.setItem("twoFactorMethod", data.twoFactorMethod);
        } else {
          localStorage.setItem("ttuniqueId", data.ttuniqueId.toString());
          localStorage.setItem("trojanAcctNumber", data.trojanAcctNumber);
          localStorage.setItem("dentifiType", data.dentifiType);
          localStorage.setItem("clientServices", data.clientServices);     
          localStorage.setItem("avaId", data.avaId);
          localStorage.setItem("avap", data.avap);
          localStorage.setItem("avaPId", data.avaPId);
          localStorage.setItem("avaPp", data.avaPp);
          localStorage.setItem("PMS", data.pms);
          localStorage.setItem("officeName", data.officeName);
          localStorage.setItem("officePhone", data.officePhone);
          localStorage.setItem("officeFax", data.officeFax);
          localStorage.setItem("isAdmin", data.isAdmin.toString());

          localStorage.setItem("twoFactorEnabled", data.twoFactorEnabled.toString());
          localStorage.setItem("twoFactorForAllUsers", data.twoFactorForAllUsers.toString());
          localStorage.setItem("twoFactorMethod", data.twoFactorMethod);
        }

        this.spinnerService.requestEnded()
      }),
      catchError(this.handleError)
    );
  }

  getManualAppointments(entity: ApptRequest): Observable<ManualAppointments[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    this.spinnerService.requestStarted();
    return this.http.post<ManualAppointments[]>(DENTIFI_API_URL + "/" + "GetManualAppointments", entity, { headers: httpOptions }).pipe(
      tap(data => this.spinnerService.requestEnded()),
      catchError(this.handleError)
    );
  }

  getJustAppointmentver2ApptsForToday(ttuniuqId: number): Observable<string[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
  
    return this.http.post<string[]>(DENTIFI_API_URL + "/" + "GetJustAppointmentVer2AppointmentsForToday",ttuniuqId,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );
  }


  getPatientSubscriberBenEligInfo(entity: PatientSubscriberInfoRequest): Observable<PatientSubscriberEligBen> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<PatientSubscriberEligBen>(DENTIFI_API_URL + "/" + "GetEligBenInfo", entity, { headers: httpOptions }).pipe(
      //tap(data => console.log("All: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getPatientRequestFormInfo(entity: PatientRequestFormBody): Observable<PatientRequestInfo> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<PatientRequestInfo>(DENTIFI_API_URL + "/" + "GetPatientRequestFormDetails", entity, { headers: httpOptions }).pipe(
      //tap(data => console.log("All: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }

  getNotifications(ttuniqueId:number, currentDate:string): Observable<AppointmnetWithNoMemberId[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<AppointmnetWithNoMemberId[]>(DENTIFI_API_URL + "/" + "GetNotifications", { TTuniqueId: ttuniqueId, CurrentDate: currentDate}, { headers: httpOptions }).pipe(
      //tap(data => console.log("All: " + JSON.stringify(data))),
      catchError(this.handleError)
    );
  }


  getStaticHTML271(transid: number, isTrusted: boolean): Observable<string> {
    let fd = new FormData();
    let avaId: string = localStorage.getItem("avaId");
    let avap: string = localStorage.getItem("avap");
    fd.append("TRANSID", transid.toString());
    fd.append("USERID", avaId);
    fd.append("PASSWORD", avap);
    
    return this.http.post("https://avalon2.trojanonline.com/webconn/wc.dll?webproc~Return_Elig_HTML", fd, {
      responseType: 'text',
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*/*"
      }
    }).pipe(
      map(response => this.mapStaticHtml(response, isTrusted)),
      catchError(this.handleError)
    );
  }

  getStaticHTML271API(transid: number, isTrusted: boolean): Observable<string> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post(DENTIFI_API_URL + "/" + "GetHtml271", transid, { headers: httpOptions, responseType: 'text' }).pipe(
      map(response => this.mapStaticHtml(response, isTrusted)),
      catchError(this.handleError)
    );
  }

  getStaticHTMLBSPlan(trojanId: string, isTrusted: boolean): Observable<string> {
    let fd = new FormData();
    let avaPId: string = localStorage.getItem("avaPId");
    let avaPp: string = localStorage.getItem("avaPp");
    fd.append("TROJANID", trojanId);
    fd.append("USERID", avaPId);
    fd.append("PASSWORD", avaPp);
    return this.http.post("https://avalon2.trojanonline.com/webconn/wc.dll?webproc~Get_BS_PLAN_HTML", fd, {
      responseType: 'text',
      headers: {
        "Content-Type": "multipart/form-data",
        "Accept": "*/*"
      }
    }).pipe(
      map(response => this.mapStaticHtml(response, isTrusted)),
      catchError(this.handleError)
    );
  }

  getClinics(ttuniqueId: number) {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<Clinic[]>(DENTIFI_API_URL + "/" + "GetClinics", ttuniqueId, { headers: httpOptions }).pipe(
      catchError(this.handleError)
    );  
  }


  getTrojanHolidays(): Observable<Date[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.get<Date[]>(DENTIFI_API_URL + "/" + "GetTrojanHolidays", { headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }


  submitFormRequest(entity:SubmitRequestFormBody): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "SubmitRequestForm",entity ,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );       
  }

  submitEligRequestForManualPatient(entity:EligibilityRequestFormBody): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    console.log(entity);
    return this.http.post<any>(DENTIFI_API_URL + "/" + "SubmitEligRequestForm",entity ,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );       
  }

  getTrojanPlans(entity:SearchPlanRequestBody): Observable<SearchedPlan[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<SearchedPlan[]>(DENTIFI_API_URL + "/" + "GetTrojanPlans",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  matchTrojanPlan(entity:MatchPlanBodyRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "MatchTrojanPlan",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  matchTrojanPlanForManualPatient(entity:MatchPlanBodyRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "MatchTrojanPlanToManualPatient",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  unmatchTrojanPlan(entity:MatchPlanBodyRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "UnMatchTrojanPlan",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  unmatchTrojanPlanForManualPatient(entity:MatchPlanBodyRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "UnMatchTrojanPlanForManualPatient",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  getPayerList(): Observable<Payer[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.get<Payer[]>(DENTIFI_API_URL + "/" + "GetPayerList", { headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  deleteManualPatient(entity:DeleteManualPatientRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "DeleteManualPatient",entity,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  getPdfFax(fileName: string, faxDetId: string, trojanAcct: string, patientId:number): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    
    return this.http.post(DENTIFI_API_URL + "/" + "GetFaxPdf",{"fileName":fileName, "FaxDetId" :faxDetId, "TrojanAcct": trojanAcct, "PatientId": patientId},{ headers:httpOptions, responseType: 'blob'}).pipe(
      catchError(this.handleError)
    );     
  }
  
  getPHPdf(patientId: string, ttuniqueId: string): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    
    return this.http.post(DENTIFI_API_URL + "/" + "GetPhPdf",{"patientId":patientId, "TtuniqueId" :ttuniqueId},{ headers:httpOptions, responseType: 'text' }).pipe(
      catchError(this.handleError)
    );     
  }

  getClientNetworks(custNo: string): Observable<ClientNetwork[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken')); 
    this.spinnerService.requestStarted();
    return this.http.post<ClientNetwork[]>(DENTIFI_API_URL + "/" + "GetClientNetowrks",{"custNo":custNo},{ headers:httpOptions}).pipe(
      tap(data => this.spinnerService.requestEnded()),
      catchError(this.handleError)
    );     
  }

  saveClientNetworks(networks: ClientNetwork[]): Observable<ClientNetwork[]> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken')); 
    this.spinnerService.requestStarted();
    return this.http.post<ClientNetwork[]>(DENTIFI_API_URL + "/" + "SaveClientNetowrks",networks,{ headers:httpOptions}).pipe(
      tap(data => this.spinnerService.requestEnded()),
      catchError(this.handleError)
    );     
  }

  getRemainingBalanceAndDownloadDate(TrojanAcct: string, TTuniqueId: number): Observable<RemainingBalDownloadTime> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<RemainingBalDownloadTime>(DENTIFI_API_URL + "/" + "GetRemainingBalanceAndLastDownloadTime", {TrojanAcct:TrojanAcct, TTuniqueId: TTuniqueId} ,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }

  getExistingRequestStatus(ttuniqueId: number,patientId :number,isPri:boolean): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "GetPreviousRequestStatus", {TTuniqueId: ttuniqueId,PatientId:patientId,IsPri:isPri} ,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );     
  }
  sendAllEligibilityRequests(trjAcctNumb: string, selectedDate: string, ttuniqueId: number): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    this.spinnerService.requestStarted();
    return this.http.post<any>(DENTIFI_API_URL + "/" + "SendAllEligibilityRequests", {TrojanAcctNumber:trjAcctNumb,SelectedDate:selectedDate, TtuniqueId: ttuniqueId}, { headers: httpOptions }).pipe(
      tap(data => this.spinnerService.requestEnded()),
      catchError(this.handleError)
    );
  }

  linkSelectedPlans(planRequests: LinkPlanRequest[]): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "LinkPlans", planRequests, { headers: httpOptions }).pipe(
      catchError(this.handleError)
    );
  }
  
  reLinkPlans(planRequests: LinkPlanRequest[]): Observable<any> {
    let httpOptions = new HttpHeaders().set("Authorization", "Bearer " + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "reLinkPlans", planRequests, { headers: httpOptions }).pipe(
      catchError(this.handleError)
    );
  }

  togglePatientPortionEstimated(apptId: number, patId: number, ttuniqueId:number, isManual: boolean): Observable<any> {
    let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "PatientPortionEstimated",{ApptId: apptId, PatId: patId, TtuniqueId: ttuniqueId, IsManual: isManual},{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );
  } 

  toggleVerifiedEligManually(requestBody: VerifiedManuallyRequest): Observable<any> {
    let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('bearerToken'));
    return this.http.post<any>(DENTIFI_API_URL + "/" + "VerifiedManually",requestBody,{ headers: httpOptions }).pipe(
      catchError(this.handleError)
    );
  } 

  getRequestLogs(ttuniqueId: number, startDate: string, endDate: string) : Observable<RequestLog[]> {
    let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('bearerToken'));
    return this.http.post<RequestLog[]>(DENTIFI_API_URL + "/" + "GetRequestLogs", {TTuniqueId: ttuniqueId, StartDate: startDate, EndDate: endDate}, { headers: httpOptions }).pipe(catchError(this.handleError));
  }
  getReLinkQueue(ttuniqueId: number, startDate: string, endDate: string,isOlderDataNeeded:boolean) : Observable<any> {
    let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('bearerToken'));
   return this.http.post<any>(DENTIFI_API_URL + "/" + "getReLinkQueue", {TTuniqueId: ttuniqueId, StartDate: startDate, EndDate: endDate,IsOlderDataNeeded:isOlderDataNeeded}, { headers: httpOptions }).pipe(catchError(this.handleError));
   
  }

  getMetrics(ttuniqueId: number, startDate: string, endDate: string) : Observable<Metrics> {
    this.spinnerService.requestStarted();
    let httpOptions = new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('bearerToken'));
    return this.http.post<Metrics>(DENTIFI_API_URL + "/" + "GetMetrics", {TTuniqueId: ttuniqueId, StartDate: startDate, EndDate: endDate}, { headers: httpOptions }).pipe(tap(data => this.spinnerService.requestEnded()),catchError(this.handleError));
  }






  private mapStaticHtml(htmlString: string, isTrusted: boolean): string {
    return isTrusted ?
      htmlString :
      this.domSanitizer.sanitize(SecurityContext.HTML, htmlString);
  }

  private handleError(err: HttpErrorResponse) {
    
    //Later we may want to send the error to some remote logging infrastructure instead of just logging it to the console.
    let errorMessage = "";
    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      // The backend returned an unsuccessful reponse code. The response body may contain clues as to waht went wrong.
      if (err.status === 401) {
        localStorage.removeItem("bearerToken");
        localStorage.removeItem("ttuniqueId");
        localStorage.removeItem("trojanAcctNumber");
        localStorage.removeItem("dentifiType");
        localStorage.removeItem("clientServices");
        localStorage.removeItem("userName");
        localStorage.removeItem("avaId");
        localStorage.removeItem("avap");
        localStorage.removeItem("avaPId");
        localStorage.removeItem("avaPp");
        localStorage.removeItem("PMS");
        localStorage.removeItem("officeName");
        localStorage.removeItem("officePhone");
        localStorage.removeItem("officeFax");
        localStorage.removeItem("isAdmin");
        window.location.href = "/login";
      }

      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`
    }

    console.log(errorMessage);
    return throwError(errorMessage);
  }

}
