import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//import { RouterModule } from '@angular/router';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { FormsModule } from '@angular/forms';
import { MustMatchDirective } from './MustMatchDirective';
//import { PasswordValidator } from '../CustomeDirectives/PasswordRequirementValidator/PasswordValidator';


  
    @NgModule({
      imports: [
        CommonModule
        //RouterModule.forChild(routes),
        //FontAwesomeModule,
        //FormsModule
        
      ],
      declarations: [MustMatchDirective
        //MustMatchDirective,
        //PasswordValidator
    ],
      exports:[MustMatchDirective]
    })  
    export class MustMatchModule {
    }