import { Injectable, EventEmitter } from '@angular/core';    
import { Subscription } from 'rxjs/internal/Subscription';  

@Injectable({
  providedIn: 'root'
})
export class EventEmitterRefreshRemainingBalanceService {

  invokeApptNavMenuMethods = new EventEmitter();    
  subsVar: Subscription;    
    
  constructor() { }    
    
  oninvokeApptNavMenuMethods() {    
    this.invokeApptNavMenuMethods.emit();    
  }   
}
