

export class SubmitRequestFormBody {
    TTUniqueId: number;
    SubFName: string;
    SubLName: string;
    SubDOB:string;
    IsPri: boolean;
    ApptId: number;
    ApptDate: string;
    TrojanAcctNumber:string;

    TrojanId: string;

    SubSSN:string;
    SubID:string;
    SubZip:string;
    MappedPayerId:number;

    PatFName:string;
    PatLName:string;
    PatDOB:string;
    PatID:string;

    EmployerName:string;
    EmployerCity: string;         
    EmployerState: string;         
    EmployerPhone: string;         
    EmployerZip: string;         
    EmployerFax: string;         

    CarrierName:string;
    CarrierGroupNo:string;
    CarrierCity:string;          
    CarrierState:string;          
    CarrierPhone:string;          
    CarrCode: string;

    PrefProvider:string; 
    
    DateNeeded: string;           
    TimeNeeded: string;            
    ReturnMethod: string;
    
    Submitted: string;
    Remarks: string;

    IsEligChecked:boolean;
    IsFaxChecked:boolean;
    IsTPChecked:boolean;
    IsPHChecked:boolean;

    ForManualPatient: boolean;

}