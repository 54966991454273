import { Component, Input, OnInit } from '@angular/core';
import { SecurityService } from "../../security.service"
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { AppUserAuth } from '../../app-user-auth';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-two-fa-pop-up',
  templateUrl: './two-fa-pop-up.component.html',
  styleUrls: ['./two-fa-pop-up.component.css']
})
export class TwoFAPopUpComponent implements OnInit   {
  authCode: string = "";
  faUser = faUser;
  @Input() userMail:string="";
  @Input() userName:string="";
  errorMessage:string="";
  isError:boolean=false;
  securityObject: AppUserAuth = null;
  processingAppointments: boolean = false;
  loading: boolean = false;
  returnUrl: string;

  constructor(private securityService: SecurityService, private route: ActivatedRoute, private dentifiAPIService: DentifiApiService,public activeModal: NgbActiveModal) { }

ngOnInit() {
  //this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

  if (this.userMail) {
    //this.userMail = this.route.snapshot.queryParamMap.get("m");
    this.userMail = atob(this.userMail);
  }

  // if (this.route.snapshot.queryParamMap.has("u")) {
  //   this.userName = this.route.snapshot.queryParamMap.get("u");
  //   //this.userName = atob(this.userName);
  // }
}

  submit() {
    this.processingAppointments = true;
    this.loading = true;
    this.securityService.loginTwoStep(this.userMail,this.userName,this.authCode, false).subscribe(result => {
      if(result.message == "Success"){
 
        //this.requestSubmitted = true;
        this.processingAppointments = false; 
        this.activeModal.close(true);

      }else{
        this.activeModal.close(false);
       
      }               
    });
    // this.ngOnInit();
  }

}
