import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { MatchPlanBodyRequest } from 'src/app/Custom Datatypes/MatchPlanBodyRequest';
import { SearchedPlan } from 'src/app/Custom Datatypes/SearchedPlan';
import { SearchPlanRequestBody } from 'src/app/Custom Datatypes/SearchPlanRequestBody';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { EventServiceForMatchingPlanService } from 'src/app/Services/event-service-for-matching-plan.service';

function atleastFieldProvided(c: AbstractControl): {[key: string]: boolean} | null {
  let trojanId = c.get("trojanId");
  let empName = c.get("employerName");
  let policNo = c.get("policyNo");

  if (trojanId.value.trim() == "" && empName.value.trim() == "" && policNo.value.trim() == "") {
    return { "noInputProvided": true}
  }
  return null;
}

@Component({
  selector: 'app-search-and-match-plan',
  templateUrl: './search-and-match-plan.component.html',
  styleUrls: ['./search-and-match-plan.component.scss']
})
export class SearchAndMatchPlanComponent implements OnInit {
  trojanPlanInnerHtml: SafeHtml = "";
  @Input() trojanId: string;
  @Input() patientId: number;
  @Input() patChart: string;
  @Input() isPri: string;
  @Input() isManual: boolean;


  ttuniqueId: number = parseInt(localStorage.getItem("ttuniqueId"));
  plans: SearchedPlan[] = [];
  requestForm: FormGroup;
  errorMessage: string = "";

  constructor(private dentifiAPIService: DentifiApiService, private domSanitizer: DomSanitizer, private fb: FormBuilder,private evntService: EventServiceForMatchingPlanService) { }

  ngOnInit() {
    this.plans = [];
    this.requestForm = this.fb.group({
      trojanId: [''],
      employerName: [''],
      policyNo: [''],

    },{validator:atleastFieldProvided});
  }
  searchPlans() {
    let request = new SearchPlanRequestBody();
    request.TrojanId = this.requestForm.get("trojanId").value;
    request.EmployerName = this.requestForm.get("employerName").value;
    request.PolicyNo = this.requestForm.get("policyNo").value;

    this.dentifiAPIService.getTrojanPlans(request).subscribe({
      next: plans => {
        this.plans = plans;         
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    }); 
  }


  downloadPlan(trojanId: string) {
    this.dentifiAPIService.getStaticHTMLBSPlan(trojanId,true).subscribe({
      next: plan => {
        this.replaceTrojanPlanHtml(plan);
      },
      error: err => {
        this.errorMessage = err;
        console.log("error occured getting TP: " + this.errorMessage);
      }
    }); 
  }

  private replaceTrojanPlanHtml(innerHTML: string): void {
    this.trojanPlanInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);         

  
  }

  reset() {
    console.log("reset!");
    this.plans = [];
    this.trojanPlanInnerHtml = "";
  }

  backToSearch() {
    this.trojanPlanInnerHtml = null;
  }

  matchPlanToPatient(searchedTrojanPlan: string) {
    let typeOfInsurance = "";
    let isSec = "";
    if (this.isPri == 'true') {
      typeOfInsurance = "primary insurance";
      isSec = "N";
    } else {
      typeOfInsurance = "secondary insurance";
      isSec = "Y";
    }
    let matchPatient = confirm(`You are planning to link trojan plan ID ${searchedTrojanPlan} to patient chart ${this.patChart} under ${typeOfInsurance}. Do you wish to continue?`);
    if (matchPatient) {
      let requestBody = new MatchPlanBodyRequest();
      requestBody.TrojanId = searchedTrojanPlan.trim();
      requestBody.IsSec = isSec; 
      requestBody.PatientId = this.patientId;
      requestBody.TTUniqueId = this.ttuniqueId;
      this.dentifiAPIService.matchTrojanPlan(requestBody).subscribe({
        next: response => {      
          //Reload appointment data using EventService that sends an event to the component that is subscribed to the event listener. 
          this.trojanId = searchedTrojanPlan;
          this.evntService.onSearchAndMatchPlantComponentButtonClick();
/*           alert(response.message);  */
        },
        error: err => {
          this.errorMessage = err;
          console.log("error matching plan: " + this.errorMessage);
          alert(err.Message);
        }
      }); 
    } else {
      return;
    }
  }

  matchPlanToManualPatient(searchedTrojanPlan: string) {
    let matchPatient = confirm(`You are planning to link trojan plan ID ${searchedTrojanPlan} to patient ${this.patientId}. Do you wish to continue?`);
    if (matchPatient) {
      let requestBody = new MatchPlanBodyRequest();
      requestBody.TrojanId = searchedTrojanPlan.trim();
      requestBody.PatientId = this.patientId;
      requestBody.TTUniqueId = this.ttuniqueId;
      this.dentifiAPIService.matchTrojanPlanForManualPatient(requestBody).subscribe({
        next: response => {      
          //Reload appointment data using EventService that sends an event to the component that is subscribed to the event listener. 
          this.trojanId = searchedTrojanPlan;
          this.evntService.onSearchAndMatchPlantComponentButtonClick();
/*           alert(response.message);  */
        },
        error: err => {
          this.errorMessage = err;
          console.log("error matching plan: " + this.errorMessage);
          alert(err.Message);
        }
      }); 
    } else {
      return;
    }
  }

}
