export class ApptRequest {
    ttuniqueId: Number = 0;
    apptdate: string = "";
    dentifiType: string = "";
    trojanAcctNumber: string = "";
    clientServices: string = "";
    clinicId: string = "";
    apptEndDate:string="";
    showTodayOnly : boolean = true;
    PatientType:string ="2";
    

    constructor(ttuniqudId:Number, apptDate: string, dentifiType: string, trjAcctNum: string, clientServices: string, clinicId: string, PatientType : string, apptEndDate:string="", showTodayOnly : boolean) {
        this.ttuniqueId = ttuniqudId;
        this.apptdate = apptDate;
        this.dentifiType = dentifiType;
        this.trojanAcctNumber = trjAcctNum;
        this.clientServices = clientServices;
        this.clinicId = clinicId;
        this.apptEndDate = apptEndDate;
        this.showTodayOnly = showTodayOnly;
        this.PatientType = PatientType;
    }

}