

export class EligibilityRequestFormBody {
    TTUniqueId: number;
    TrojanAcctNumber: string;
    SubFName: string;
    SubLName: string;
    SubDOB:string;
    SubSSNOrMemId:string;
    PatFName:string;
    PatLName:string;
    PatDOB:string;
    CarrierName:string;
    SubChart: string;
    SubZip:string;
    ApptDate: string;
    PatChart: string;
    RelToSub: string;

    MappedPayerId:number;
    GroupNo: string;


    


}