export class VerifiedManuallyRequest {
    IsPri: boolean;
    PatientId: number;
    TtuniqueId: number;
    MappedPayerId: number;
    RelToSub: string;
    SubSSN: string;
    SubFName: string;
    SubLName: string;
    SubDOB: string;
    SubZip: string;
    PatFName: string;
    PatLName: string;
    PatDOB: string;
}