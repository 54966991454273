import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, FormGroup } from '@angular/forms';

import { ContainCapitalizationNumSym } from './ContainCapitalizationNumSym';

@Directive({
    selector: '[mustMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidator, multi: true }]
})
export class PasswordValidator implements Validator {
    @Input('containCapitalNumSym') containCapitalNumSym: string;

    validate(formGroup: FormGroup): ValidationErrors {
        return ContainCapitalizationNumSym(this.containCapitalNumSym)(formGroup);
    }
}