import { TrialInfo } from "./Trial-Info";

export class AppUserAuth {
    userName: string = "";
    bearerToken: string = "";
    isAuthenticated: boolean = false;
    ttuniqueId: Number = 0;
    trojanAcctNumber: string = "";
    dentifiType: string = "";
    pms: string = "";
    clientServices: string = "";
    trialInformation: TrialInfo;
    resetPassword: boolean = false;
    isEnabled: boolean  = true;
    avaId: string = "";
    avap: string = "";
    avaPId: string = "";
    avaPp: string = "";
    officeName: string = "";
    officePhone: string = "";
    officeFax: string = "";
    isAdmin: boolean;
    twoFactorRequired:boolean=false;
    userEmail:string="";
    twoFactorEnabled :boolean = false;
    twoFactorForAllUsers : boolean = false;
    twoFactorMethod : string;
    success : boolean = true;
}