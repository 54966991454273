import { Component, EventEmitter, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { ManualAppointments } from 'src/app/Custom Datatypes/ManualAppointments';
import { ManualPatientRow } from 'src/app/Custom Datatypes/ManualPatientRow';
import { MatchPlanBodyRequest } from 'src/app/Custom Datatypes/MatchPlanBodyRequest';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';
import { EventServiceForMatchingPlanService } from 'src/app/Services/event-service-for-matching-plan.service';
import { ManualApptBenEligEventEmiterService } from 'src/app/Services/manual-appt-ben-elig-event-emiter.service';
import { SearchAndMatchPlanComponent } from 'src/app/shared/SearchPlansForMatching/search-and-match-plan.component';

@Component({
  selector: 'app-manual-ben-elig-info',
  templateUrl: './manual-ben-elig-info.component.html',
  styleUrls: ['./manual-ben-elig-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManualBenEligInfoComponent implements OnInit {
  ttuniqueId: number = parseInt(localStorage.getItem("ttuniqueId"));
  appt: ManualAppointments;
  errorMessage: string = "";
  eligInnerHtml: SafeHtml = "";
  tPInnerHtml: SafeHtml = "";
  formattedPatDOB: string = "";
  formattedSubDOB: string = "";
  activeId: number;
  dentifiType: string = localStorage.getItem("dentifiType");

  @Output() notificationFromChild: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(NgbNav, { static: true })
  ngbNavElement: NgbNav;
  selectTab: number = 1;
  todaysDate: Date = new Date();
  @ViewChildren(SearchAndMatchPlanComponent) childrenSearchPlanAndMatchComponents: QueryList<SearchAndMatchPlanComponent>;
  childSearchPlanAndMatchComponent: SearchAndMatchPlanComponent;
  constructor(private dentifiAPIService: DentifiApiService, private eventEmitterService: ManualApptBenEligEventEmiterService, private domSanitizer: DomSanitizer, private evntService: EventServiceForMatchingPlanService) { }


  ngOnInit() {
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeBenfitEligTabsetComponentFunction.subscribe((eligElement: ManualPatientRow) => {
          this.getBenAndEligInfo(eligElement);
        });
    }
    if (this.appt != null && this.appt != undefined) {
      //Get formatted Pat and Sub DOB
      let patDateAndTime = this.appt.DOB.split(" ");

      this.formattedPatDOB = patDateAndTime[0];
      let subDateAndTime = this.appt.SubDOB.split(" ");
      this.formattedSubDOB = subDateAndTime[0];


      if (this.appt.EligStatus != "P") {
        //Get Elig Response
        this.getEligibiltyHtml(this.appt.TransId);
        //Get TP Responses
        this.getTPHtml(this.appt.TrojanId);
      }
      //Set active id in tab set
      this.ngbNavElement.select(this.selectTab);
    }

  }
  ngAfterViewInit(): void {
    this.childrenSearchPlanAndMatchComponents.changes.subscribe((comps: QueryList<SearchAndMatchPlanComponent>) => {
      this.childSearchPlanAndMatchComponent = comps.first;
    });
  }


  getEligibiltyHtml(transid: number) {
    this.dentifiAPIService.getStaticHTML271(transid, true).subscribe({
      next: html271 => {
        this.replaceHtml(html271);
      },
      error: err => {
        this.errorMessage = err;
        console.log("error occured getting html271: " + this.errorMessage);
      }
    });

  }

  getTPHtml(trojanId: string) {
    this.dentifiAPIService.getStaticHTMLBSPlan(trojanId, true).subscribe({
      next: html271 => {
        this.replaceTPHtml(html271);
      },
      error: err => {
        this.errorMessage = err;
        console.log("error occured getting TP: " + this.errorMessage);
      }
    });

  }
  getBenAndEligInfo(eligElement: ManualPatientRow) {
    this.resetData();
    if (eligElement) {
      this.appt = eligElement.appt;
      this.selectTab = eligElement.activeId;
      //Clear Prexisting Data
      this.ngOnInit();
      if (this.childSearchPlanAndMatchComponent != undefined) {
        this.childSearchPlanAndMatchComponent.ngOnInit();
      }
    }
  }

  private replaceHtml(innerHTML: string): void {
    this.eligInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
  }

  private replaceTPHtml(innerHTML: string): void {
    this.tPInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
  }


  unMatchPlan(trojanId: string) {

    let matchPatient = confirm(`You are planning to unmatch trojan plan ID ${trojanId} to patient ${this.appt.ID}. Do you wish to continue?`);
    if (matchPatient) {
      let requestBody = new MatchPlanBodyRequest();
      requestBody.TrojanId = trojanId.trim();
      requestBody.PatientId = this.appt.ID;
      requestBody.TTUniqueId = this.ttuniqueId;
      this.dentifiAPIService.unmatchTrojanPlanForManualPatient(requestBody).subscribe({
        next: response => {
          //Reload appointment data using EventService that sends an event to the component that is subscribed to the event listener. 
          this.evntService.onSearchAndMatchPlantComponentButtonClick();
          this.resetData();
          alert(response.message);
        },
        error: err => {
          this.errorMessage = err;
          console.log("error matching plan: " + this.errorMessage);
          alert(err.Message);
        }
      });
    } else {
      return;
    }
  }

  togglePatientPortionEstimate() {
    this.dentifiAPIService.togglePatientPortionEstimated(-1, this.appt.ID, this.ttuniqueId, true).subscribe({
      next: resp => {
        this.appt.PatPortionEstimated = resp.patPortionEstimated;
      },
      error: err => {
        console.log(err);
      }
    });
  }


  resetData() {
    this.eligInnerHtml = "";
    this.tPInnerHtml = "";
    this.appt = null;
  }

}
