import { Output, QueryList, ViewChildren, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { EligibilityElement } from '../Custom Datatypes/EligibilityElement';
import { MatchPlanBodyRequest } from '../Custom Datatypes/MatchPlanBodyRequest';
import { PatientRow } from '../Custom Datatypes/PatientRow';
import { PatientSubscriberEligBen } from '../Custom Datatypes/PatientSubscriberEligBen';
import { PatientSubscriberInfoRequest } from '../Custom Datatypes/PatientSubscriberInfoRequest';
import { VerifiedManuallyRequest } from '../Custom Datatypes/VerifiedManaullyRequest';
import { DentifiApiService } from '../Services/dentifi-api.service';
import { EventEmitterService } from '../Services/event-emitter.service';
import { EventServiceForMatchingPlanService } from '../Services/event-service-for-matching-plan.service';
import { SearchAndMatchPlanComponent } from './SearchPlansForMatching/search-and-match-plan.component';
import { SpinnerService } from '../shared/Spinner/SpinnerService';


@Component({
  selector: 'app-benefit-elig-tabset',
  templateUrl: './benefit-elig-tabset.component.html',
  styleUrls: ['./benefit-elig-tabset.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BenefitEligTabsetComponent implements OnInit {
  ttuniqueId: number = parseInt(localStorage.getItem("ttuniqueId"));
  dentifiType: string = localStorage.getItem("dentifiType");
  dentifiServices: string = localStorage.getItem("clientServices");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  patientSubscriberEligBen: PatientSubscriberEligBen;
  eligElement: EligibilityElement;
  errorMessage: string = "";
  priEligInnerHtml: SafeHtml = "";
  secEligInnerHtml: SafeHtml = "";
  priTPInnerHtml: SafeHtml = "";
  secTPInnerHtml: SafeHtml = "";
  priAltTPInnerHtml: SafeHtml = "";
  secAltTPInnerHtml: SafeHtml = "";
  activeId: number;
  @Output() notificationFromChild: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(NgbNav, { static: true })
  ngbNavElement: NgbNav;
  selectTab: number = 1;
  todaysDate: Date = new Date();
  @ViewChildren(SearchAndMatchPlanComponent) childrenSearchPlanAndMatchComponents: QueryList<SearchAndMatchPlanComponent>;
  childSearchPlanAndMatchComponent: SearchAndMatchPlanComponent;
  noCoverageTable: boolean;
  noSecCoverageTable: boolean;
  isTabDisabled :boolean=false;

  @Output() stopLoader = new EventEmitter();


  constructor(private dentifiAPIService: DentifiApiService, private eventEmitterService: EventEmitterService, private domSanitizer: DomSanitizer, private evntService: EventServiceForMatchingPlanService,private spinnerService: SpinnerService) { }


  ngOnInit() {

    this.eventEmitterService.
      invokeBenfitEligTabsetComponentFunction.subscribe((eligElement: PatientRow) => {
        this.getBenAndEligInfo(eligElement);
      });
this.callElig();
    
  }
  ngAfterViewInit(): void {
    this.childrenSearchPlanAndMatchComponents.changes.subscribe((comps: QueryList<SearchAndMatchPlanComponent>) => {
      this.childSearchPlanAndMatchComponent = comps.first;
    });
  }

  callElig(){
    if (this.eligElement != null && this.eligElement != undefined) {
     //Get Elig Responses
     if(this.eligElement.IsPriFailed){
      this.priEligInnerHtml = this.eligElement.PriErrorMsg;
     }else{
      this.getEligibiltyHtml(this.eligElement.PriTransId, true);
     }
     if(this.eligElement.IsSecFailed){
      this.secEligInnerHtml = this.eligElement.SecErrorMsg;
     }else{
      this.getEligibiltyHtml(this.eligElement.SecTransId, false);
     }
      //Get Pat, Sub, and Cov Table Info. 
      let patSubCovRequest = new PatientSubscriberInfoRequest(this.ttuniqueId, this.eligElement.Chart, this.eligElement.PatientId, this.trojanAcct)
      this.getPatCharCovTable(patSubCovRequest);
      //Get TP Responses
      if (this.dentifiType != 'DE') {
        this.getTPHtml(this.eligElement.BenefitInfo.PriTrojanId, true, false);
        this.getTPHtml(this.eligElement.BenefitInfo.SecTrojanId, false, false);
      }
      if (this.dentifiServices.includes('D')) {
        this.getTPHtml(this.eligElement.BenefitInfo.AltPriTrojanId, true, true);
        this.getTPHtml(this.eligElement.BenefitInfo.AltSecTrojanId, false, true);
      }


      //disable tabs related to secondary if secondary plan is not available
      if(this.eligElement.SecEligStatus == ''){
        this.isTabDisabled=true;
      }

      //Set active id in tab set
      this.ngbNavElement.select(this.selectTab);
    }

  }

  getPatCharCovTable(entity: PatientSubscriberInfoRequest) {
    this.dentifiAPIService.getPatientSubscriberBenEligInfo(entity).subscribe({
      next: response => {
        this.patientSubscriberEligBen = response;
        if (this.patientSubscriberEligBen.PriCovTable) {
          this.noCoverageTable = false;
        } else {
          this.noCoverageTable = true;
        }
        if (this.patientSubscriberEligBen.SecCovTable) {
          this.noSecCoverageTable = false;
        } else {
          this.noSecCoverageTable = true;
        }
      },
      error: err => {
        this.errorMessage = err;
        console.log("error occured getting PatCharCovTable: " + this.errorMessage);
      }
    });
  }

  getEligibiltyHtml(transid: number, isPri: boolean) {
    this.spinnerService.requestStarted();
    try{
      this.dentifiAPIService.getStaticHTML271(transid, true).subscribe({
        next: html271 => {
          /*         var fs = html271.substring(13);
                  // Get datastream as Array
                  var charData = new TextEncoder().encode(fs);
                  //Turn number array into byte-array
                  var binData = new Uint8Array(fs);
                  //Decompress using pako library
                  try {
                    var data = pako.inflateRaw(binData);
          
                  } catch (err) {
                    console.log(err);
                  }
                
                  var strData = String.fromCharCode.apply(null, new Uint16Array(data)); */
          //For long strings we use the below method. http://stackoverflow.com/a/20604561/774398
          /* var string = new TextDecoder().decode(data); */
          this.spinnerService.requestEnded();
          this.replaceHtml(html271, isPri);
        },
        error: err => {
          this.spinnerService.requestEnded();
          this.errorMessage = err;
          console.log("error occured getting html271: " + this.errorMessage);
        }
      });
    }catch(ex){
      this.spinnerService.requestEnded();
    }
    

  }

  getTPHtml(trojanId: string, isPri: boolean, isAlt: boolean) {
    this.spinnerService.requestStarted();
    try{
      this.dentifiAPIService.getStaticHTMLBSPlan(trojanId, true).subscribe({
        next: html271 => {
          this.spinnerService.requestEnded();
          this.replaceTPHtml(html271, isPri, isAlt);
        },
        error: err => {
          this.spinnerService.requestEnded();
          this.errorMessage = err;
          console.log("error occured getting TP: " + this.errorMessage);
        }
      });
    }catch(ex){
      this.spinnerService.requestEnded();
    }
    
  }


  getBenAndEligInfo(eligElement: PatientRow) {
    this.resetData();
    if (eligElement) {
      this.eligElement = eligElement.eligibilityElement;
      this.selectTab = eligElement.activeId;
      //Clear Prexisting Data
      //this.ngOnInit(); //need to check as its called in circular
      this.callElig();
      if (this.childSearchPlanAndMatchComponent != undefined) {
        this.childSearchPlanAndMatchComponent.ngOnInit();
      }
    }
  }

  private replaceHtml(innerHTML: string, isPri: boolean): void {
    if (isPri) {
      this.priEligInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
    } else {
      this.secEligInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
    }

  }

  private replaceTPHtml(innerHTML: string, isPri: boolean, isAlt: boolean): void {
    if (isPri) {
      if (isAlt) {
        this.priAltTPInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
      } else {
        this.priTPInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
      }

    } else {
      if (isAlt) {
        this.secAltTPInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
      } else {
        this.secTPInnerHtml = this.domSanitizer.bypassSecurityTrustHtml(innerHTML);
      }
    }

  }

  togglePatientPortionEstimate() {
    this.dentifiAPIService.togglePatientPortionEstimated(this.eligElement.ApptId, this.eligElement.PatientId, this.ttuniqueId, false).subscribe({
      next: resp => {
        this.eligElement.PatPortionEstimated = resp.patPortionEstimated;
      },
      error: err => {
        console.log(err);
      }
    });
  }

  togglePatientManualVerification(isPri: boolean) {
    let requestBody = new VerifiedManuallyRequest();
    requestBody.IsPri = isPri;
    requestBody.PatientId = this.eligElement.PatientId;
    requestBody.TtuniqueId = this.ttuniqueId;
    requestBody.PatFName = this.eligElement.FName;
    requestBody.PatLName = this.eligElement.LName;
    requestBody.PatDOB = this.eligElement.DOB;
    if (isPri) {
      requestBody.MappedPayerId = this.eligElement.PriMappedId;
      requestBody.RelToSub = this.patientSubscriberEligBen.RelationToPriSub;
      if (this.patientSubscriberEligBen.PriSSN.trim().length > 0) {
        requestBody.SubSSN = this.patientSubscriberEligBen.PriSSN;
      } else {
        requestBody.SubSSN = this.patientSubscriberEligBen.PriMemberId;
      }
      requestBody.SubFName = this.patientSubscriberEligBen.PriSubFName;
      requestBody.SubLName = this.patientSubscriberEligBen.PriSubLName;
      requestBody.SubDOB = this.patientSubscriberEligBen.PriSubDOB;
      requestBody.SubZip = this.patientSubscriberEligBen.PriZipCode;
    } else {
      requestBody.MappedPayerId = this.eligElement.SecMappedId;
      requestBody.RelToSub = this.patientSubscriberEligBen.RelationToSecSub;
      if (this.patientSubscriberEligBen.SecSSN.trim().length > 0) {
        requestBody.SubSSN = this.patientSubscriberEligBen.SecSSN;
      } else {
        requestBody.SubSSN = this.patientSubscriberEligBen.SecMemberId;
      }
      requestBody.SubFName = this.patientSubscriberEligBen.SecSubFName;
      requestBody.SubLName = this.patientSubscriberEligBen.SecSubLName;
      requestBody.SubDOB = this.patientSubscriberEligBen.SecSubDOB;
      requestBody.SubZip = this.patientSubscriberEligBen.SecZipCode;
    }
    console.log(requestBody);
    this.dentifiAPIService.toggleVerifiedEligManually(requestBody).subscribe({
      next: resp => {
          if (isPri) {
            this.eligElement.PriEligManuallyVerified = resp.verifiedManually;
          } else {
            this.eligElement.SecEligManuallyVerified = resp.verifiedManually;
          }
      },
      error: err => {
        console.log(err);
      }
    });
    
  }



  compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  unMatchPlan(isSec: string, trojanId: string) {
    let typeOfInsurance = "";
    if (isSec == 'N') {
      typeOfInsurance = "primary insurance";
    } else {
      typeOfInsurance = "secondary insurance";
    }
    let matchPatient = confirm(`You are planning to unmatch trojan plan ID ${trojanId} to patient chart ${this.eligElement.Chart} under ${typeOfInsurance}. Do you wish to continue?`);
    if (matchPatient) {
      let requestBody = new MatchPlanBodyRequest();
      requestBody.TrojanId = trojanId.trim();
      requestBody.IsSec = isSec;
      requestBody.PatientId = this.eligElement.PatientId;
      requestBody.TTUniqueId = this.ttuniqueId;
      this.dentifiAPIService.unmatchTrojanPlan(requestBody).subscribe({
        next: response => {
          //Reload appointment data using EventService that sends an event to the component that is subscribed to the event listener. 
          this.evntService.onSearchAndMatchPlantComponentButtonClick();
          this.resetData();
          alert(response.message);
        },
        error: err => {
          this.errorMessage = err;
          console.log("error matching plan: " + this.errorMessage);
          alert(err.Message);
        }
      });
    } else {
      return;
    }
  }

  resetData() {
    this.priTPInnerHtml = "";
    this.secTPInnerHtml = "";
    this.priEligInnerHtml = "";
    this.secEligInnerHtml = "";
    this.priAltTPInnerHtml = "";
    this.secAltTPInnerHtml = "";
    this.patientSubscriberEligBen = null;
    this.noCoverageTable = false;
    this.noSecCoverageTable = false;
  }

}
