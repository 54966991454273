export class LinkPlanRequest {
    TrojanId: string = "";
    PatientId: number;
    PatChart: string = "";
    TrojanAcctNumber: string = "";
    IsPri: string = "";
    TTUniqueId: number;
    IsManual:boolean = false;
    ApptId: number;
  RowID: any;
  TrojanID: string;
}