
import { Component, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { faUserCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppUserAuth } from '../security/app-user-auth';
import { DentifiApiService } from '../Services/dentifi-api.service';
import { FormControl } from '@angular/forms';
import { ApptRequest } from '../Custom Datatypes/ApptRequest';
import { Observable, of } from 'rxjs';
import { map, startWith} from 'rxjs/operators';
import { NgbDatepicker, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestFormModalComponent } from '../shared/request form/request-form-modal.component';
import { Clinic } from '../Custom Datatypes/Clinic';
import { EventServiceForMatchingPlanService } from '../Services/event-service-for-matching-plan.service';
import { BenefitEligTabsetComponent } from '../shared/benefit-elig-tabset.component';
import { ManualAppointments } from '../Custom Datatypes/ManualAppointments';
import { ManualPatientRow } from '../Custom Datatypes/ManualPatientRow';
import { ManualApptBenEligEventEmiterService } from '../Services/manual-appt-ben-elig-event-emiter.service';
import { SendEligibilityModalComponent } from './SendEligibilityModal/send-eligibility-modal.component';
import { DeleteManualPatientRequest } from '../Custom Datatypes/DeleteManualPatientRequest';
import { ClientNetworkComponent } from '../shared/ClientNetwork/client-network.component';
import { UserManagementComponent } from '../shared/UserManagement/user-management.component';
import { LinkPlanRequest } from '../Custom Datatypes/LinkPlansRequest';




@Component({
  selector: 'app-manual-appointments',
  templateUrl: './manual-appointments.component.html',
  styleUrls: ['./manual-appointments.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ManualAppointmentsComponent  implements OnInit {
  faUser = faUserCircle;
  faSearch = faSearch;
  userName: string = "";
  selectedDate: any;
  startDate: any;
  formattedSelectedDate: string = "";
  appointments: ManualAppointments[];
  errorMessage: string = "";
  filter: FormControl = new FormControl('');
  AppUserAuth: AppUserAuth;
  dentifiService: string = localStorage.getItem("clientServices");
  dentifiType: string = localStorage.getItem("dentifiType");
  appointments$: Observable<any[]>;
  currentSelectedPatient;
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  listOfClinics: Clinic[];
  @ViewChild('d', { static: true }) datepicker: NgbDatepicker;
  processingAppointments: boolean = false;
  todaysDate: Date = new Date();
  @ViewChildren(BenefitEligTabsetComponent) childrenBenefitEligTabsetComponent: QueryList<BenefitEligTabsetComponent>;
  private childBenefitEligTabsetComponent: BenefitEligTabsetComponent;
  isAdmin: string = localStorage.getItem("isAdmin");
  planLinkCheckboxes: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("planLinkCheckbox") as HTMLCollectionOf<HTMLInputElement>;
  dateSelectionLable :string = "Show last 30 days";
  showToday : boolean = true;
  formattedSelectedDateRange : string = "";
  isManual : boolean = true;
  constructor(private dentifiAPIService: DentifiApiService, private eventEmitterService: ManualApptBenEligEventEmiterService, private modal: NgbModal, private evntService: EventServiceForMatchingPlanService) {

  }


  ngOnInit() {
    //For reloading data when child component performs the matches plan function.
    if (this.evntService.subsVar == undefined) {
      this.evntService.subsVar = this.evntService.
        invokeAppointmentComponentFunction.subscribe(() => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
        });
    }

    this.userName = localStorage.getItem("userName");
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedDate = this.startDate;
    this.processAndDisplayAppointments();

    setTimeout(() => {
      this.logOut();
      location.reload();
    }, 1500000);

  }

  ngAfterViewInit(): void {
      this.childrenBenefitEligTabsetComponent.changes.subscribe((comps: QueryList <BenefitEligTabsetComponent>) =>
      {
          this.childBenefitEligTabsetComponent = comps.first;
      });
  }

  selectPatient(event: any, item: ManualAppointments) {
    this.currentSelectedPatient = item.ID;
  }

  processAndDisplayAppointments(): void {
      //Remove existing elig, tp, cov table info
      this.eventEmitterService.onBenfitEligTabsetComponentButtonClick(null)  
    this.currentSelectedPatient = null;

    this.processingAppointments = true;
    this.appointments$ = of([]);
    this.formattedSelectedDate = `${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;
    let apptRequest = new ApptRequest(parseInt(this.ttuniqueId), this.formattedSelectedDate, this.dentifiType, localStorage.getItem("trojanAcctNumber"), this.dentifiService, "", "","",this.showToday);


    this.dentifiAPIService.getManualAppointments(apptRequest).subscribe({
      next: appointments => {
        this.appointments = appointments;
        this.appointments$ = of(this.appointments);
        this.appointments$ = this.filter.valueChanges.pipe(
          startWith(''),
          map(text => this.search(text)));
        this.processingAppointments = false;
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }


  incrementCalendarDayByOne() {
    if (this.processingAppointments) {

    } else {
      let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      this.processAndDisplayAppointments();
    }
  }

  decrementCalendarDayByOne() {
    if (this.processingAppointments) {

    } else {
      let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      this.processAndDisplayAppointments();
    }
  }

  showClientNetworkModal() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(ClientNetworkComponent, options);
    modalRef.result.then(result => {
    },
      reason => {
        
      });
  }

  showUserManagementModal() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(UserManagementComponent, options);
    modalRef.result.then(result => {
    },
      reason => {
        
      });  
  }

  linkSelectedPlans() {
    let numberOfPlans = 0;
    for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
      if (this.planLinkCheckboxes[x].checked) {
        numberOfPlans++;
      }
    }
    if (numberOfPlans == 0) {
      alert("Please select at least 1 checkbox next to the trojan plans above to link to your practice management system.");
      return;
    } else {
      let planRequests: LinkPlanRequest[] = [];
      for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
        if (this.planLinkCheckboxes[x].checked) {
          let planRequest = new LinkPlanRequest;
          let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
          planRequest.TrojanId = patientPlanInfo[0];
          planRequest.PatChart = patientPlanInfo[1];
          planRequest.IsPri = patientPlanInfo[2];
          planRequest.PatientId = parseInt(patientPlanInfo[3]);
          planRequest.TrojanAcctNumber = this.trojanAcct;
          planRequest.TTUniqueId = parseInt(this.ttuniqueId);
          planRequest.IsManual = true;
          planRequests.push(planRequest);

        }
      }
      this.dentifiAPIService.linkSelectedPlans(planRequests).subscribe({
        next: result => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    }

  }

  linkAllPlans() {

    if (confirm(`You are attempting to link all matched non-expired plans for appointment date ${this.formattedSelectedDate} to your Practice Management System. Do you wish to continue?`)) {
      let planRequests: LinkPlanRequest[] = [];
      for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
  
        let planRequest = new LinkPlanRequest;
        let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
        planRequest.TrojanId = patientPlanInfo[0];
        planRequest.PatChart = patientPlanInfo[1];
        planRequest.IsPri = patientPlanInfo[2];
        planRequest.PatientId = parseInt(patientPlanInfo[3]);
        planRequest.TrojanAcctNumber = this.trojanAcct;
        planRequest.TTUniqueId = parseInt(this.ttuniqueId);
        planRequest.IsManual = true;
        planRequests.push(planRequest);
  
  
      }
      this.dentifiAPIService.linkSelectedPlans(planRequests).subscribe({
        next: result => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    } else {
      return;
    }

  }


  search(text: string): ManualAppointments[] {
    return this.appointments.filter(appointment => {
      const term = text.toLowerCase();
      return appointment.ID.toString().toLowerCase().includes(term)
        || appointment.FName.toLowerCase().includes(term)
        || appointment.LName.toLowerCase().includes(term);
    });
  }

  callBenefitEligFunction(eligElement: ManualAppointments, activeId: number) {
    let patientRow = new ManualPatientRow(eligElement, activeId);
    this.eventEmitterService.onBenfitEligTabsetComponentButtonClick(patientRow);
  }


  showRequestForm(appt: ManualAppointments) {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(RequestFormModalComponent, options);
    modalRef.componentInstance.manualAppt = appt;
    modalRef.componentInstance.apptdate = this.formattedSelectedDate;
    modalRef.componentInstance.forManualPatient = true;

    modalRef.result.then(result => {
      this.dentifiAPIService.submitFormRequest(result).subscribe({
        next: result => {
          console.log(result)
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }

  showEligForm() {
    let options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(SendEligibilityModalComponent, options);
    modalRef.componentInstance.apptdate = this.formattedSelectedDate;
    modalRef.result.then(result => {
      this.dentifiAPIService.submitEligRequestForManualPatient(result).subscribe({
        next: result => {
          console.log(result);
          this.processAndDisplayAppointments();
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });  
  }
  deletePatient(patientId: any) {
    if(confirm(`Are you sure you want to delete patient ${patientId}?`)) {
      let request = new DeleteManualPatientRequest();
      request.TTUniqueId = parseInt(this.ttuniqueId);
      request.ManualPatientId = patientId;
      this.dentifiAPIService.deleteManualPatient(request).subscribe({
        next: result => {
/*           alert(result.message); */
          this.processAndDisplayAppointments();
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    }
    return;
  }



  logOut() {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("ttuniqueId");
    localStorage.removeItem("trojanAcctNumber");
    localStorage.removeItem("dentifiType");
    localStorage.removeItem("clientServices");
    localStorage.removeItem("userName");
    localStorage.removeItem("avaId");
    localStorage.removeItem("avap");
    localStorage.removeItem("avaPId");
    localStorage.removeItem("avaPp");
    localStorage.removeItem("PMS");
    localStorage.removeItem("officeName");
    localStorage.removeItem("officePhone");
    localStorage.removeItem("officeFax");
    localStorage.removeItem("isAdmin");
  }

  compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);

    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }
  
  setDateToGetData(){
    this.showToday = !this.showToday;
    
    if(!this.showToday){
      let prevMonthDate = new Date();
      prevMonthDate.setMonth(this.selectedDate.month -1);

      this.formattedSelectedDateRange = `${prevMonthDate.getMonth()}/${prevMonthDate.getDate()}/${prevMonthDate.getFullYear()} - ${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;
      this.dateSelectionLable = "Show Today Only";
    }else{
      this.dateSelectionLable = "Show last 30 days";
    }
    this.processAndDisplayAppointments();
  }


}
