import { Component, Injectable, OnInit, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { faUserCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AppUserAuth } from '../security/app-user-auth';
import { EligibilityElement } from '../Custom Datatypes/EligibilityElement';
import { DentifiApiService } from '../Services/dentifi-api.service';
import { FormControl } from '@angular/forms';
import { ApptRequest } from '../Custom Datatypes/ApptRequest';
import { Observable, of } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { EventEmitterService } from '../Services/event-emitter.service';
import { PatientRow } from '../Custom Datatypes/PatientRow';
import { NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepicker, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { RequestFormModalComponent } from '../shared/request form/request-form-modal.component';
import { Clinic } from '../Custom Datatypes/Clinic';
import { EventServiceForMatchingPlanService } from '../Services/event-service-for-matching-plan.service';
import { BenefitEligTabsetComponent } from '../shared/benefit-elig-tabset.component';
import { ClientNetworkComponent } from '../shared/ClientNetwork/client-network.component';
import { EventEmitterRefreshRemainingBalanceService } from '../Services/event-emitter-refresh-remaining-balance.service';
import { LinkPlanRequest } from '../Custom Datatypes/LinkPlansRequest';
import { UserManagementComponent } from '../shared/UserManagement/user-management.component';
import { NotificationComponent } from '../shared/ClientNotification/notification.component';
import { toInteger } from '@ng-bootstrap/ng-bootstrap/util/util';
import { AppointmnetWithNoMemberId } from '../Custom Datatypes/AppointmnetWithNoMemberId';
import { SpinnerService } from '../shared/Spinner/SpinnerService';
import { ExportApptComponent } from '../shared/export-appt/export-appt.component';
import { TwoFAPopUpComponent } from '../security/2fa/2fa-popup/two-fa-pop-up.component';
import { SendEligibilityModalComponent } from '../ManualAppointments/SendEligibilityModal/send-eligibility-modal.component';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[1], 10),
        month: parseInt(date[0], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if(!date){
      return '';
    }else{
      return date.month.toString().padStart(2,"0")+ this.DELIMITER + date.day.toString().padStart(2,"0") + this.DELIMITER + date.year
    }
  }
}

@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentComponent implements OnInit {
  faUser = faUserCircle;
  faSearch = faSearch;
  userName: string = "";
  selectedDate: any;
  startDate: any;
  pms: string = localStorage.getItem("PMS");
  formattedSelectedDate: string = "";
  endformatedselectedDate: string = "";
  appointmentDate: string = "";
  appointments: EligibilityElement[];
  errorMessage: string = "";
  filter: FormControl = new FormControl('');
  AppUserAuth: AppUserAuth;
  dentifiService: string = localStorage.getItem("clientServices");
  dentifiType: string = localStorage.getItem("dentifiType");
  hasDoublePlanService: boolean = false;
  appointments$: Observable<any[]>;
  currentSelectedPatient;
  currentSelectedApptId;
  ttuniqueId: string = localStorage.getItem("ttuniqueId");
  listOfClinics: Clinic[];
  @ViewChild('d', { static: true }) datepicker: NgbDatepicker;
  processingAppointments: boolean = false;
  clinicId: string = "";
  todaysDate: Date = new Date();
  @ViewChildren(BenefitEligTabsetComponent) childrenBenefitEligTabsetComponent: QueryList<BenefitEligTabsetComponent>;
  private childBenefitEligTabsetComponent: BenefitEligTabsetComponent;
  showSendAllElig: boolean;
  trojanAcct: string = localStorage.getItem("trojanAcctNumber");
  planLinkCheckboxes: HTMLCollectionOf<HTMLInputElement> = document.getElementsByClassName("planLinkCheckbox") as HTMLCollectionOf<HTMLInputElement>;
  isAdmin: string = localStorage.getItem("isAdmin");
  searchByList: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName("searchList") as HTMLCollectionOf<HTMLElement>;
  selectedSearchByFilter: string = "All"
  selectedClinic: string = "All";
  notifications: AppointmnetWithNoMemberId[] = [];
  dontShowAlert: boolean = false;
  isOpenDental: boolean = false;
  showAlertIcon: boolean = false;
  date: string;
  currentSelectedPatId;
  isManual: boolean = false;
  PatientType: string = "2";
  dateSelectionLable: string = "Show last 30 days";
  showToday: boolean = true;
  formattedSelectedDateRange: string = "";
  formattedSelectedEndDate: string = "";
  ascending: string = "";
  selectedColumn: string = "";
  selectedEndDate: any;
  SelectedAppointmnetDate : string="";

  constructor(private ngbCalendar: NgbCalendar, private dateAdapter: NgbDateAdapter<string>, private dentifiAPIService: DentifiApiService, private eventEmitterService: EventEmitterService, private modal: NgbModal, private evntService: EventServiceForMatchingPlanService, private eventServiceForNavAppt: EventEmitterRefreshRemainingBalanceService, private spinnerService: SpinnerService) {
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedDate = this.startDate;
    this.appointmentDate = `${this.startDate.month}/${this.startDate.day}/${this.startDate.year}`;
  }


  ngOnInit() {
    //For reloading data when child component performs the matches plan function.

    //this.selectedDate = this.ConvertDateObject(this.selectedDate);
    if(typeof this.selectedDate != "object")
    {
      alert("Please Enter Valid Date");
      return;
    }

    if (this.evntService.subsVar == undefined) {
      this.evntService.subsVar = this.evntService.
        invokeAppointmentComponentFunction.subscribe(() => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
          this.currentSelectedApptId = undefined;
          this.currentSelectedPatId = undefined;
        });
    }



    //Get Missing Memebr Id notifications. 
    this.userName = localStorage.getItem("userName");

    //if (!this.dontShowAlert) {

    this.formattedSelectedDate = `${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;
    // this.formattedSelectedEndDate = 

    this.dentifiAPIService.getNotifications(parseInt(this.ttuniqueId), this.formattedSelectedDate).subscribe({
      next: notificaitons => {
        if (notificaitons.length > 0) {
          this.notifications = notificaitons;
          this.showAlertIcon = true;
          //dont show popup now, show bell icon on menu so user can understand there is some notification
          // if(this.hasOneDayPassed()){
          //  this.showClientNotification();
          // }
        }
      },
      error: err => {
        console.log(err);
      }
    });

    //}


    //Only show filter by clinic for Open Dental clients
    let pmsInfoArray = this.pms.split(";");
    let pmsName = "";
    if (pmsInfoArray.length > 0) pmsName = pmsInfoArray[0];
    if (pmsName.toLowerCase().trim() == "opendental") {
      this.isOpenDental = true;
      this.getClinics();
    }

    //this.dateSelectionLable = "Show last 30 days";
    //this.showToday = true;
    this.processAndDisplayAppointments();

    setTimeout(() => {
      this.logOut();
      location.reload();
    }, 1500000);

    //get relink queue data  
    let sDate = new Date();
    let eDate = new Date();
    eDate.setDate(sDate.getDate() + 14);
    //get relink queue for current appt
    this.getReLinkQueue(sDate, eDate, false, true);



  }
  ConvertDateObject(selectedDate: string | NgbDateStruct): NgbDateStruct {
    if (typeof selectedDate === 'object') {
      return selectedDate as NgbDateStruct;
    } else {
      const parts = selectedDate.split('/');
      if (parts.length === 3) {
        const year = +parts[2];
        const month = +parts[0];
        const day = +parts[1];
        if (!isNaN(year) && !isNaN(month) && !isNaN(day)) {
          return { year, month, day };
        }
      }
    }
  }

  // checks if one day has passed. 
  hasOneDayPassed() {
    // get today's date. eg: "7/37/2007"
    var date = new Date().toLocaleDateString();
    var lastMissingPatShownOn = localStorage.getItem('lastMissingPatShownOn');

    // if there's a date in localstorage and it's equal to the above: 
    // inferring a day has yet to pass since both dates are equal.
    if (lastMissingPatShownOn == date)
      return false;

    // this portion of logic occurs when a day has passed
    localStorage.setItem('lastMissingPatShownOn', date);
    return true;
  }

  ngAfterViewInit(): void {
    this.childrenBenefitEligTabsetComponent.changes.subscribe((comps: QueryList<BenefitEligTabsetComponent>) => {
      this.childBenefitEligTabsetComponent = comps.first;
    });
  }

  selectPatient(event: any, item: EligibilityElement) {
    this.currentSelectedPatient = item.Chart;
    this.currentSelectedApptId = item.ApptId;
    this.currentSelectedPatId = item.PatientId;
    this.isManual = item.isManualPatient;

  }
  selectAppointments(event) {
    //for Appointment PatientType=0
    //for Manually Entered PatientType=1
    //for both  PatientType=2
    this.selectedSearchByFilter = "All";
    this.filter.setValue("");
    this.search("");
    this.PatientType = event.target.value;
    this.showToday = true;
    this.dateSelectionLable = "Show last 30 days";
    this.processAndDisplayAppointments();
  }

  processAndDisplayAppointments(): void {
    //Remove existing elig, tp, cov table info
    this.eventEmitterService.onBenfitEligTabsetComponentButtonClick(null)
    this.currentSelectedPatient = null;
    this.currentSelectedApptId = null;
    this.currentSelectedPatId = null;
    this.selectedColumn = "";
    this.ascending = "";

    const selectedDateDay= String(this.selectedDate.day).padStart(2,"0");
    const selectedDateMonth= String(this.selectedDate.month).padStart(2,"0");
    this.SelectedAppointmnetDate =  `${selectedDateMonth}/${selectedDateDay}/${this.selectedDate.year}`;
    this.selectedEndDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    this.selectedEndDate.setDate(this.selectedEndDate.getDate() - 30);
    const selectedEndDateDay= String(this.selectedEndDate.getDate()).padStart(2,"0");
    const selectedEndDateMonth= String(this.selectedEndDate.getMonth() + 1).padStart(2,"0");
    this.formattedSelectedEndDate = `${selectedEndDateMonth}/${selectedEndDateDay}/${this.selectedEndDate.getFullYear()}`

    this.processingAppointments = true;
    this.appointments$ = of([]);
    this.formattedSelectedDate = `${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;
   
    let apptRequest = new ApptRequest(parseInt(this.ttuniqueId), this.formattedSelectedDate, this.dentifiType, this.trojanAcct, this.dentifiService, this.clinicId, this.PatientType, "", this.showToday);

    //Determine whether to show send all elig button
    let selectedDate = new Date(this.formattedSelectedDate);
    let todayMonth = this.todaysDate.getMonth() + 1;
    let selectedMonth = selectedDate.getMonth() + 1;
    let todayDay = this.todaysDate.getDate();
    let selectedDay = selectedDate.getDate();
    if (todayDay >= 1 && todayDay <= 9) {
      if (selectedDay >= 1 && selectedDay <= 9 && todayMonth == selectedMonth) {
        this.showSendAllElig = true;
      } else {
        this.showSendAllElig = false;
      }
    } else {
      this.showSendAllElig = false;
    }


    this.dentifiAPIService.getAppointments(apptRequest).subscribe({
      next: appointments => {
        this.appointments = appointments;
        this.appointments$ = of(this.appointments);

        this.appointments$ = this.filter.valueChanges.pipe(
          startWith(''),
          map(text => this.search(text)));
        this.processingAppointments = false;

      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  showEligForm() {
    let options: NgbModalOptions = {
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(SendEligibilityModalComponent, options);
    modalRef.componentInstance.apptdate = this.appointmentDate;
    modalRef.result.then(result => {
      this.dentifiAPIService.submitEligRequestForManualPatient(result).subscribe({
        next: result => {
          if (result.message == "Chart is already exist") {
            alert("A patient with this chart number already exists: " + result.fName + " " + result.lName +".");
            return;
          }
          if (result.message == "Patient exist but eligibility sent") {
            alert("This patient is already exist, we are sending eligibility request for this patient.");
          }
          if(result.message == "Appointment Already exist"){
            alert("Appointment for this patient already exists.");
          }
          console.log(result);
          this.showToday = true;
          this.dateSelectionLable = "Show last 30 days";
          this.processAndDisplayAppointments();
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    },
      reason => {
        console.log(`Dismissed reason: ${reason}`);
      });
  }


  incrementCalendarDayByOne() {
    if (this.processingAppointments) {

    } else {
      if(typeof this.selectedDate != "object")
      {
        alert("Please Enter Valid Date");
        return;
      }
      //this.selectedDate = this.ConvertDateObject(this.selectedDate);
      let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      d.setDate(d.getDate() + 1);
      this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      this.showToday = true;
      this.dateSelectionLable = "Show last 30 days";
      this.processAndDisplayAppointments();
    }
  }

  onTodayClick() {
    //get system current date (today) and set to selectedDate 
    let d = new Date();
    this.startDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.selectedDate = this.startDate;
    //call process appointment
    this.showToday = true;
    this.dateSelectionLable = "Show last 30 days";
    this.processAndDisplayAppointments();
  }

  incrementCalendarDayByWeek() {
    if (this.processingAppointments) {

    } else {
      if(typeof this.selectedDate != "object")
      {
        alert("Please Enter Valid Date");
        return;
      }
      //this.selectedDate = this.ConvertDateObject(this.selectedDate);
      let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      d.setDate(d.getDate() + 7);
      this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      this.showToday = true;
      this.dateSelectionLable = "Show last 30 days";
      this.processAndDisplayAppointments();
    }
  }

  decrementCalendarDayByOne() {
    if (this.processingAppointments) {

    } else {
      if(typeof this.selectedDate != "object")
      {
        alert("Please Enter Valid Date");
        return;
      }
      //this.selectedDate = this.ConvertDateObject(this.selectedDate);
      let d = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
      d.setDate(d.getDate() - 1);
      this.selectedDate = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
      this.showToday = true;
      this.dateSelectionLable = "Show last 30 days";
      this.processAndDisplayAppointments();
    }
  }

  getClinics() {
    this.dentifiAPIService.getClinics(parseInt(this.ttuniqueId)).subscribe({
      next: clinics => {
        this.listOfClinics = clinics;
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }


  search(text: string): EligibilityElement[] {

    let searchByFilter = this.selectedSearchByFilter;
    const term = text.toLowerCase();
    let filteredAPPTs;
    if (searchByFilter == "Pri E" || searchByFilter == 'UTP' || searchByFilter == 'PI' || searchByFilter == 'E') {

      if (searchByFilter == 'UTP' || searchByFilter == 'PI') {
        filteredAPPTs = this.appointments.filter(appointment => {
          return appointment.PriEligStatus.toLowerCase() == searchByFilter.toLocaleLowerCase();
        });
      } else if (searchByFilter == 'E') { //return both primary and secondary
        filteredAPPTs = this.appointments.filter(appointment => {
          return appointment.PriEligStatus == 'E' || appointment.SecEligStatus == 'E';
        });
      } else if (searchByFilter == 'Pri E') { //return if patient is primary eligible
        filteredAPPTs = this.appointments.filter(appointment => {
          return appointment.PriEligStatus == 'E';
        });
      } else {
        filteredAPPTs = this.appointments.filter(appointment => {
          return appointment.PriEligStatus != '';
        });
      }



    } else if (searchByFilter == "Sec E") {
      filteredAPPTs = this.appointments.filter(appointment => {
        return appointment.SecEligStatus != '';
      });

    } else { //return all status 
      filteredAPPTs = this.appointments;
    }


    //apply search text if not empty else return filtered based on dropdown selection
    //Search Chart, F Name, L Name, PRI TP, SEC TP columns
    if (term != '') {
      return filteredAPPTs.filter(appointment => {
        return appointment.Chart.toLowerCase().includes(term)
          || appointment.FName.toLowerCase().includes(term)
          || appointment.LName.toLowerCase().includes(term)
          || appointment.BenefitInfo.PriTrojanId.toLowerCase().includes(term)
          || appointment.BenefitInfo.SecTrojanId.toLowerCase().includes(term)
          ;
      });
    } else {
      return filteredAPPTs;
    }


  }

  callBenefitEligFunction(eligElement: EligibilityElement, activeId: number) {
    let patientRow = new PatientRow(eligElement, activeId);
    //this.spinnerService.requestStarted();
    this.eventEmitterService.onBenfitEligTabsetComponentButtonClick(patientRow);
  }


  showRequestForm(appt: EligibilityElement, isPri: boolean) {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(RequestFormModalComponent, options);
    modalRef.componentInstance.eligibilityElement = appt;
    modalRef.componentInstance.apptdate = this.formattedSelectedDate;
    modalRef.componentInstance.isPri = isPri;
    modalRef.result.then(result => {
      this.dentifiAPIService.submitFormRequest(result).subscribe({
        next: result => {
          this.ngOnInit();
          this.eventServiceForNavAppt.oninvokeApptNavMenuMethods();
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    },
      reason => {

      });
  }

  showClientNotification() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(NotificationComponent, options);
    modalRef.componentInstance.notifications = this.notifications;
    modalRef.result.then(result => {
      this.dontShowAlert = result;
    },
      reason => {
      });
  }


  showClientNetworkModal() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(ClientNetworkComponent, options);
    modalRef.result.then(result => {
    },
      reason => {

      });
  }

  showUserManagementModal() {
    let options: NgbModalOptions = {
      size: 'xl',
      backdrop: 'static',
      keyboard: false
    }
    let modalRef = this.modal.open(UserManagementComponent, options);
    modalRef.result.then(result => {
    },
      reason => {

      });
  }


  getAppointmentsForClinic(clinic: string) {
    let clinicValues = clinic.split(";");
    this.showToday = true;
    this.dateSelectionLable = "Show last 30 days";
    if (clinicValues[0] == "All") {
      this.clinicId = "";
      this.selectedClinic = "All";

      this.processAndDisplayAppointments();
    } else {
      this.clinicId = clinicValues[0];
      this.selectedClinic = clinicValues[1];
      this.processAndDisplayAppointments();
    }

  }

  submitAllElig() {
    this.dentifiAPIService.sendAllEligibilityRequests(this.trojanAcct, this.formattedSelectedDate, parseInt(this.ttuniqueId)).subscribe({
      next: result => {
        console.log(result.message);
        this.ngOnInit();
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  linkSelectedPlans() {
    let numberOfPlans = 0;
    for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
      if (this.planLinkCheckboxes[x].checked) {
        numberOfPlans++;
      }
    }
    if (numberOfPlans == 0) {
      alert("Please select at least 1 checkbox next to the trojan plans above to link to your practice management system.");
      return;
    } else {
      let planRequests: LinkPlanRequest[] = [];
      for (var x = 0; x < this.planLinkCheckboxes.length; x++) {
        if (this.planLinkCheckboxes[x].checked) {
          let planRequest = new LinkPlanRequest;
          let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
          planRequest.TrojanId = patientPlanInfo[0];
          planRequest.PatChart = patientPlanInfo[1];
          planRequest.IsPri = patientPlanInfo[2];
          planRequest.PatientId = parseInt(patientPlanInfo[3]);
          planRequest.TrojanAcctNumber = this.trojanAcct;
          planRequest.TTUniqueId = parseInt(this.ttuniqueId);
          planRequest.ApptId = parseInt(patientPlanInfo[4]);//this.currentSelectedApptId;
          if (patientPlanInfo[5] == "true") {
            planRequest.IsManual = true;
          }
          else {
            planRequest.IsManual = false;
          }
          planRequests.push(planRequest);

        }
      }
      this.showToday = true;
      this.dateSelectionLable = "Show last 30 days";
      this.dentifiAPIService.linkSelectedPlans(planRequests).subscribe({
        next: result => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
          this.currentSelectedApptId = undefined;
          this.currentSelectedPatId = undefined;
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    }

  }

  linkAllPlans() {

    if (confirm(`You are attempting to link all matched non-expired plans for appointment date ${this.formattedSelectedDate} to your Practice Management System. Do you wish to continue?`)) {
      let planRequests: LinkPlanRequest[] = [];
      for (var x = 0; x < this.planLinkCheckboxes.length; x++) {

        let planRequest = new LinkPlanRequest;
        let patientPlanInfo = this.planLinkCheckboxes[x].value.split(";");
        planRequest.TrojanId = patientPlanInfo[0];
        planRequest.PatChart = patientPlanInfo[1];
        planRequest.IsPri = patientPlanInfo[2];
        planRequest.PatientId = parseInt(patientPlanInfo[3]);
        planRequest.TrojanAcctNumber = this.trojanAcct;
        planRequest.TTUniqueId = parseInt(this.ttuniqueId);
        planRequest.ApptId = parseInt(patientPlanInfo[4]); //this.currentSelectedApptId;
        if (patientPlanInfo[5] == "true") {
          planRequest.IsManual = true;
        }
        else {
          planRequest.IsManual = false;
        }
        planRequests.push(planRequest);


      }
      this.showToday = true;
      this.dateSelectionLable = "Show last 30 days";
      this.dentifiAPIService.linkSelectedPlans(planRequests).subscribe({
        next: result => {
          this.processAndDisplayAppointments();
          this.currentSelectedPatient = undefined;
          this.currentSelectedApptId = undefined;
          this.currentSelectedPatId = undefined;
        },
        error: err => {
          this.errorMessage = err;
          console.log(this.errorMessage);
        }
      });
    } else {
      return;
    }

  }

  toggleShowFilterOptions() {
    if (this.searchByList.item(0).style.visibility == "visible") {
      this.searchByList.item(0).style.visibility = "hidden";
    } else {
      this.searchByList.item(0).style.visibility = "visible";
    }
  }

  selectSearchByValue(value: string) {
    //Reset appt table to default appointments 
    this.selectedSearchByFilter = value;
    this.selectedColumn = "";
    this.ascending = "";
    this.filter.setValue("");
    this.appointments$ = this.filter.valueChanges.pipe(
      startWith(''),
      map(text => this.search(text)));
  }


  logOut() {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("ttuniqueId");
    localStorage.removeItem("trojanAcctNumber");
    localStorage.removeItem("dentifiType");
    localStorage.removeItem("clientServices");
    localStorage.removeItem("userName");
    localStorage.removeItem("avaId");
    localStorage.removeItem("avap");
    localStorage.removeItem("avaPId");
    localStorage.removeItem("avaPp");
    localStorage.removeItem("PMS");
    localStorage.removeItem("officeName");
    localStorage.removeItem("officePhone");
    localStorage.removeItem("officeFax");
    localStorage.removeItem("isAdmin");
  }

  compareDate(date1: Date, date2: Date): number {
    // With Date object we can compare dates them using the >, <, <= or >=.
    // The ==, !=, ===, and !== operators require to use date.getTime(),
    // so we need to create a new instance of Date with 'new Date()'
    let d1 = new Date(date1); let d2 = new Date(date2);


    // Check if the dates are equal
    let same = d1.getTime() === d2.getTime();
    if (same) return 0;

    // Check if the first is greater than second
    if (d1 > d2) return 1;

    // Check if the first is less than second
    if (d1 < d2) return -1;
  }

  openFax(filePath: string, faxDetId: string, patientId: number) {
    this.dentifiAPIService.getPdfFax(filePath, faxDetId, this.trojanAcct, patientId).subscribe({
      next: response => {

        var file = new Blob([response], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL, 'FAXDetails', 'menubar=1,resizable=1,width=850,height=550')
      },
      error: err => {
        this.errorMessage = err;
        console.log(this.errorMessage);
      }
    });
  }

  stopLoader() {
    //this.spinnerService.requestEnded();
  }

  getReLinkQueue(startDate, endDate, isOlderDataNeeded, isFromLoad) {
    if (!this.showAlertIcon) {
      this.dentifiAPIService.getReLinkQueue(parseInt(this.ttuniqueId), startDate, endDate, isOlderDataNeeded).subscribe({
        next: data => {
          if (data.length > 0) {
            this.showAlertIcon = true;
          } else if (isFromLoad) {
            //get relink queue data  
            let sDate = new Date();
            let eDate = new Date();
            eDate.setDate(sDate.getDate() + 14);
            //get relink queue for old appt
            this.getReLinkQueue(sDate, eDate, true, false);
          }
        },
        error: err => {
          console.log(err);
        }
      });
    }
  }

  sortColumn: string = '';
  sortDirection: string = 'asc'; // 'asc' for ascending, 'desc' for descending

  sort(column: string) {
    if (column === this.sortColumn) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      this.ascending = this.sortDirection;
      this.selectedColumn = column;
    } else {
      this.sortColumn = column;
      this.sortDirection = 'asc';
      this.ascending = this.sortDirection;
      this.selectedColumn = column;
    }

    this.appointments$.pipe(
      take(1) // take only one emission
    ).subscribe(sortAppointments => {
      sortAppointments.sort((a, b) => {
        let aValue;
        let bValue;
        if (column === 'ApptDate') {
          aValue = this.parseDate(a[column]);
          bValue = this.parseDate(b[column]);
          const result = this.sortDirection === 'asc' ? aValue.getTime() - bValue.getTime() : bValue.getTime() - aValue.getTime();
          return result;
        } else {
          aValue = a[column].toLowerCase();
          bValue = b[column].toLowerCase();
          const result = this.sortDirection === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
          return result;
        }
      });

      // Update the appointments$ Observable with the sorted appointments
      this.appointments$ = of(sortAppointments);
    });
  }

  parseDate(dateString: string): Date {
    const [month, day, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  }

  setDateToGetData() {
    this.showToday = !this.showToday;

    if (!this.showToday) {
      let prevMonthDate = new Date();
      prevMonthDate.setMonth(this.selectedDate.month - 1);

      this.formattedSelectedDateRange = `${prevMonthDate.getMonth()}/${prevMonthDate.getDate()}/${prevMonthDate.getFullYear()} - ${this.selectedDate.month}/${this.selectedDate.day}/${this.selectedDate.year}`;
      this.dateSelectionLable = "Show Today Only";
    } else {
      this.dateSelectionLable = "Show last 30 days";
    }
    this.processAndDisplayAppointments();
    //this.showToday = true;
  }

}
