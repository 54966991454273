import { EligibilityElement } from "./EligibilityElement";
import { ManualAppointments } from "./ManualAppointments";

export class ManualPatientRow {
    appt: ManualAppointments;
    activeId: number;
    constructor(appt:ManualAppointments, actId: number ) {
        this.appt = appt;
        this.activeId = actId;
    }
}