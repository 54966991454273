import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppointmnetWithNoMemberId } from 'src/app/Custom Datatypes/AppointmnetWithNoMemberId';
import { DentifiApiService } from 'src/app/Services/dentifi-api.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NotificationComponent implements OnInit {
  notifications: AppointmnetWithNoMemberId[] = [];
  dontShowAlert: boolean = false;
  checkBox: HTMLCollectionOf<HTMLElement> = document.getElementsByClassName("dontShowCheckbox") as HTMLCollectionOf<HTMLElement>;

  constructor(public activeModal: NgbActiveModal, private dentifiAPIService: DentifiApiService) { }

  ngOnInit() {
  }

  cancelRequest() {
    this.activeModal.close();
  }

}
 